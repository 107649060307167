<template>
  <div class="home" id="home">
    <div class="header">
      <div class="header-leftPart">
        <img src="../assets/suwen_platform_logo.png" class="logo" @click="toFirst" />
        <el-dropdown placement="bottom-start" style="margin-left: 20px">
          <span class="el-dropdown-link" style="color: #337ab7; font-size: 24px">
            <i class="el-icon-menu"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item style="font-size: 18px; color: #409eff" v-for="i in serverOptions" :key="i.value"
              :index="i.index" @click.native="chooseServer(i)">
              <i :class="i.icon"></i>
              <span>{{ i.value }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <p>
          <!-- <el-badge value="TechRadar" class="item" type="primary">素问科技雷达</el-badge> -->
          <!-- 素问科技雷达 TechRadar -->
          <!-- <span class="version" v-if="env != 'pro'">{{ version }}</span> -->
        </p>
      </div>
      <div style="display: flex; justify-content: center" v-if="$route.path != '/index' && env == 'dev'">
        <el-input placeholder="请输入企业名称" v-model="searchValue" class="input-with-select" style="width: 20vw" size="small"
          @keyup.enter.native="searchCompanyList(searchValue)">
          <el-button slot="append" icon="el-icon-search" type="primary"
            @click="searchCompanyList(searchValue)"></el-button>
        </el-input>
      </div>
      <div class="header-rightPart">
        <!-- <div class="search">
          <img class="icon" src="@/assets/images/search.png" />
          <input
            class="input"
            v-model="companyParams.keyword"
            placeholder="请输入公司名"
            @keydown.enter="
              companyParams.page = 1;
              getCompanyByBoard(1);
            "
          />
        </div> -->
        <div class="exit">
          {{ account }}, 您好！
          <el-dropdown placement="bottom" trigger="click">
            <!-- <el-tooltip content="请完善用户信息" placement="bottom" effect="dark" v-model="showUserTips" manual> -->
            <!-- <el-badge
              is-dot
              :hidden="!showUserTips"
              style="index: 10; margin: 0 10px 0 10px"
            > -->
            <div class="user">
              <i class="el-icon-user-solid"></i>
            </div>
            <!-- </el-badge> -->
            <!-- </el-tooltip> -->
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="handleUserInfo"
                >个人中心</el-dropdown-item
              > -->
              <el-dropdown-item @click.native="handleClickPersonal">个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftPart" v-if="$route.path != '/index'" :style="$route.path == '/firstPage' ||
        $route.path == '/industry_structure' ||
        sideCollapse ||
        $route.path.indexOf('/company_search') != -1 ||
        $route.path == '/personal'
        ? 'width: 0'
        : 'width: 280px'
        ">
        <!-- <el-menu
          style="margin-bottom: 10px"
          :default-active="nowActive"
          class="el-menu-vertical-demo"
          :collapse="true"
          @select="handleSelect"
        >
          <el-menu-item
            v-for="i in serverOptions"
            :key="i.value"
            :index="i.index"
            @click.native="chooseServer(i)"
          >
            <i :class="i.icon"></i>
            <span slot="title">{{ i.value }}</span>
          </el-menu-item>
          <el-button
            v-if="
              $route.path != '/firstPage' &&
              $route.path != '/industry_structure' &&
              sideCollapse
            "
            size="small"
            style="position: absolute; bottom: 17px; left: 15px"
            icon="el-icon-arrow-right"
            @click="sideCollapse = false"
            type="info"
          ></el-button>
        </el-menu> -->
        <el-button v-if="$route.path != '/firstPage' &&
          $route.path != '/industry_structure' &&
          sideCollapse
          " size="small" style="position: absolute; bottom: 35px; left: 30px; z-index: 999" icon="el-icon-arrow-right"
          @click="sideCollapse = false" type="info"></el-button>
        <div class="checkBox" v-loading="industryTreeLoading" v-if="$route.path != '/firstPage' &&
          $route.path != '/industry_structure' &&
          !sideCollapse &&
          $route.path.indexOf('/company_search') == -1 &&
          $route.path != '/personal'
          " style="margin-top: 10px; margin-left: 20px">
          <div class="centerPart-header">
            <div class="tab">
              <!-- <div class="tab-company tab-selected">
              {{ serverValue }}
            </div> -->
              <b style="font-size: 20px; color: white">产业链</b>
            </div>
          </div>
          <!-- <p class="tab-company tab-selectedSmall">产业结构树</p> -->
          <div class="selectContent">
            <!-- 已注释 start-->
            <!-- <el-select
              v-if="$route.path != '/category_graph'"
              v-model="select_value"
              placeholder="请选择"
              size="medium"
              style="margin: 10px 10px 10px 10px"
              @change="handleChangeSelect"
            >
              <el-option
                v-for="item in industryImportantList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select> -->
            <!-- <el-tree
              v-if="$route.path != '/category_graph'"
              ref="industryTree"
              :expand-on-click-node="false"
              style="margin-bottom: 10px; width: 197px"
              :data="industryTree"
              :props="defaultProps"
              @node-click="handleNodeClick"
              node-key="node_id"
              :default-expanded-keys="[first_node]"
            >
              <span class="custom-tree-node" slot-scope="{ node }">
                <span v-html="node.label"></span>
              </span>
            </el-tree> -->
            <!-- 已注释 stop-->

            <div>
              <!-- 已注释 start-->
              <!-- <p style="width: 207px;font-size: 16px;margin-bottom: 5px;cursor: pointer;padding-left: 10px;" :style="index_industry==i.name? 'color: #409EFF':''" v-for="i in industryImportantList" :key="i.name" @click="selectIndustry(i.name)" v-html="i.name_fake"></p> -->

              <!-- <el-input
                placeholder="请输入产业名称"
                v-model="searchNodeParams.query"
                class="input-with-select"
                style="
                  width: 95%;
                  margin-left: 2.5%;
                  margin-top: 10px;
                  margin-bottom: 10px;
                "
                size="small"
                @keyup.enter.native="handleNodeSearch()"
              >
                <el-button
                  class="industry_search_icon"
                  slot="append"
                  icon="el-icon-search"
                  type="primary"
                  size="mini"
                  @click="handleNodeSearch()"
                ></el-button>
              </el-input> -->
              <!-- 已注释 stop-->

              <el-autocomplete size="small" class="input-with-select" v-model="searchNodeParams.query"
                :fetch-suggestions="querySearchAsync" placeholder="请输入产业名称" @select="handleSelectSearch">
                <el-button class="industry_search_icon" slot="append" icon="el-icon-search" type="primary" size="mini"
                  @click="handleNodeSearch()"></el-button>
              </el-autocomplete>

              <el-menu default-active="1" :default-openeds="openeds" class="link_menu" :unique-opened="true">
                <el-submenu index="1" class="menu_my_sub">
                  <template slot="title">
                    <img class="slide_icon" src="@/assets/images/my_subscribe.png" />
                    <span>我的订阅</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item v-for="node in subscribeNode" :key="node.id" :index="node.node_id"
                      @click="selectIndustry(node)">{{ node.name }}</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>

                <!-- <el-menu-item index="2">
                  <i class="el-icon-menu"></i>
                  <span slot="title">全部产业链</span>
                </el-menu-item> -->
                <el-submenu index="2" class="link_menu_item">
                  <template slot="title">
                    <!-- <i class="el-icon-menu"></i> -->
                    <img class="slide_icon" src="@/assets/images/all_node.png" />
                    <span>产业链</span>
                  </template>
                  <!-- <el-menu-item-group>
                    <el-menu-item
                      v-for="node in industryImportantList"
                      :key="node.name"
                      :index="node.domain_id"
                      @click="selectIndustry(node)"
                      >{{ node.name }}</el-menu-item
                    >
                  </el-menu-item-group> -->
                  <!-- <el-menu-item-group
                    v-if="searchNodeList.length != 0"
                    class="industry_node_search_list"
                    v-loading="industryNodeSearchLoading"
                  >
                    <el-menu-item
                      v-for="node in searchNodeList"
                      :key="node.name"
                      :index="node.node_id"
                      @click="selectIndustry(node)"
                      >{{ node.name }}</el-menu-item
                    >
                  </el-menu-item-group> -->
                  <div class="btnShowMore" v-if="searchNodeCount >
                    searchNodeParams.page * searchNodeParams.page_size &&
                    searchNodeParams.query != ''
                    ">
                    <el-button type="info" size="mini" @click="showMoreIndustryNode()">加载更多</el-button>
                  </div>
                  <el-menu-item-group>
                    <div style="display: flex; margin: 10px 10px 10px 10px">
                      <el-select v-model="select_value" placeholder="请选择" size="medium" @change="handleChangeSelect">
                        <el-option v-for="item in industryImportantList" :key="item.name" :label="item.name"
                          :value="item.name">
                        </el-option>
                      </el-select>
                    </div>
                    <el-tree ref="industryTree" v-loading="treeLoading" :expand-on-click-node="false"
                      style="margin-bottom: 10px; width: 100%" :data="industryTree" :props="defaultProps"
                      @node-click="handleNodeClick" node-key="node_id" :default-expanded-keys="[first_node]">
                      <span class="custom-tree-node" slot-scope="{ node }">
                        <span v-html="node.label"></span>
                      </span>
                    </el-tree>
                  </el-menu-item-group>
                </el-submenu>

                <el-submenu index="3" class="link_menu_item" v-if="is_customIndustry">
                  <template slot="title">
                    <img class="slide_icon" src="@/assets/images/my_subscribe.png" />
                    <span>我的产业链</span>
                  </template>
                  <el-menu-item-group>
                    <div style="display: flex; margin: 10px 10px 10px 10px" v-if="is_customIndustry">
                      <el-tooltip class="item" effect="dark" content="添加自定义产业链" placement="right-start">
                        <el-button type="primary" size="small" style="flex-shrink: 0; margin-left: 8px"
                          icon="el-icon-plus" @click="addCustomNode()">添加产业链</el-button>
                      </el-tooltip>
                    </div>
                    <div class="block" style="margin-bottom: 10px" v-if="customNode &&
                      customNode.length != 0 &&
                      is_customIndustry
                      ">
                      <el-tree :data="customNode" node-key="id" default-expand-all :expand-on-click-node="false"
                        @node-click="handleNodeClick">
                        <span class="custom-tree-node" slot-scope="{ node }">
                          <span>{{ node.label }}</span>
                          <!-- <span style="margin-left: 10px">
                        <el-button
                          size="mini"
                          type="text"
                          @click.stop="append(data)"
                        >
                          增加
                        </el-button>
                        <el-button
                          size="mini"
                          type="text"
                          @click.stop="remove(node, data)"
                        >
                          删除
                        </el-button>
                        <el-button
                          size="mini"
                          type="text"
                          @click.stop="edit(data)"
                        >
                          编辑
                        </el-button>
                      </span> -->
                        </span>
                      </el-tree>
                    </div>
                  </el-menu-item-group>
                </el-submenu>
                <!-- <el-tree
                  v-if="searchNodeList.length == 0 && is_normal == 0"
                  ref="industryTree"
                  :expand-on-click-node="false"
                  style="margin-bottom: 10px; width: 197px"
                  :data="industryTree"
                  :props="defaultProps"
                  @node-click="handleNodeClick"
                  node-key="node_id"
                  :default-expanded-keys="[first_node]"
                >
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <span v-html="node.label"></span>
                  </span>
                </el-tree> -->
              </el-menu>
            </div>
          </div>
          <el-button style="position: absolute; bottom: 20px; left: 15px" size="small" icon="el-icon-arrow-left"
            type="info" v-if="!sideCollapse" @click="sideCollapse = true">
          </el-button>
        </div>
      </div>

      <div class="centerPart" :style="$route.path == '/firstPage'
        ? 'box-shadow: none; background-color: #f7f7f7'
        : ''
        ">
        <div class="centerPart-header" v-if="$route.path != '/firstPage'">
          <div class="tab">
            <b style="font-size: 20px; color: white" @click="handleClickServerTitle(serverValue)" :style="serverValue == '产业图谱  >  企业图谱' ? 'cursor: pointer' : ''
              " v-if="!isIndustrialMap">{{ serverValue }}</b>
            <b style="font-size: 20px; color: white" @click="handleClickServerTitle(serverValue)" :style="serverValue == '产业图谱  >  企业图谱' ? 'cursor: pointer' : ''
              " v-else><span v-if="!is_custom">{{ serverValue }}</span>
              <span v-else>{{
                serverValue.includes(">") && serverValue.includes("产业图谱")
                ? serverValue.split(">")[0] +
                " > 我的产业链" +
                " > " +
                serverValue.split(">")[1]
                : serverValue + " > 我的产业链"
              }}</span>
            </b>
          </div>
        </div>

        <company-info v-loading="infoLoading" :companyInfoData="companyInfoData"
          v-if="company_id_selected != 0"></company-info>
        <keep-alive>
          <router-view class="centerPart-content"></router-view>
        </keep-alive>
      </div>
    </div>
    <transition name="info-ani">
      <div class="personalInfo" v-show="showPersonalInfo">
        <div class="personalInfo-layer" @click="showPersonalInfo = false"></div>
        <div class="personalInfo-content">
          <div class="personalInfo-header">
            <p class="personalInfo-header-title">个人中心</p>
            <p class="personalInfo-header-close" @click="showPersonalInfo = false">
              X
            </p>
          </div>
          <div class="userInfo-box">
            <el-radio-group v-model="userActiveTab" size="small" style="position: absolute; left: 15px; top: 6vh">
              <el-radio-button label="基本信息"></el-radio-button>
              <el-radio-button label="权限信息"></el-radio-button>
            </el-radio-group>
            <div v-if="userActiveTab != '我的收藏'" class="user" style="
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
                border: 3px solid gray;
              ">
              <i class="el-icon-user" style="font-size: 35px"></i>
            </div>
            <p v-if="userActiveTab != '我的收藏'" style="margin-bottom: 40px">
              {{ userInfoParams.username }}
            </p>
            <el-form ref="form" :model="userInfoParams" label-width="80px" style="width: 40%"
              v-if="userActiveTab == '基本信息'">
              <el-form-item label="姓名">
                <!-- {{ userInfoParams.real_name }}-->
                <el-input v-model="userInfoParams.real_name" style="width: 90%" size="small"
                  placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="vip会员">
                {{ userInfoParams.effective_time }} 到期
              </el-form-item>
              <el-form-item label="企业名">
                <el-input v-model="userInfoParams.company_name" style="width: 90%" size="small"
                  placeholder="请输入企业名"></el-input>
              </el-form-item>
              <el-form-item label="岗位">
                <el-input v-model="userInfoParams.post" style="width: 90%" size="small" placeholder="请输入岗位"></el-input>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="userInfoParams.email" style="width: 90%" size="small" placeholder="请输入邮箱"
                  disabled></el-input>
              </el-form-item>
              <el-form-item label="电话">
                <el-input v-model="userInfoParams.mobile" style="width: 90%" size="small" placeholder="请输入电话"></el-input>
              </el-form-item>
            </el-form>
            <el-form v-if="userActiveTab == '权限信息'" ref="form" label-width="120px" style="margin-top: 40px">
              <el-table :data="userTableData" border style="width: 391px; margin-left: 20px">
                <el-table-column label="模块" width="150">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <p size="medium">{{ scope.row.perm_name }}</p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="effective_time" label="过期时间" width="240">
                </el-table-column>
              </el-table>
            </el-form>
          </div>
          <div v-if="userActiveTab == '基本信息'">
            <el-button size="small" type="primary" style="position: absolute; right: 50px; bottom: 20px"
              @click="changeUserInfo">保存</el-button>
          </div>
        </div>
      </div>
    </transition>
    <el-dialog title="请选择您想查看的公司" :visible.sync="companyListVisible">
      <el-radio-group v-model="radio" v-loading="companyLoading" @change="handleChangeRadio">
        <el-radio v-for="i in companyList" :label="i.full_name" :key="i.id" style="margin-bottom: 10px">{{ i.full_name
        }}</el-radio>
      </el-radio-group>
    </el-dialog>
    <el-dialog destroy-on-close width="20%" title="自定义节点" :visible.sync="customVisible">
      <el-input placeholder="请输入节点名称" v-model="customName"></el-input>
      <el-button type="primary" @click="handleAddCustomNode" style="margin-top: 10px">确定</el-button>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="deleteCustomVisible" width="30%">
      <span>您确定要删除该节点吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCustomVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDeleteCustom">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑节点" :visible.sync="editCustomVisible" width="60%">
      <div style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        ">
        <el-input placeholder="搜索企业" v-model="searchValue" class="input-with-select"
          style="width: 12vw; margin: 0 0 0 5px" size="small"
          @keyup.enter.native="searchCompanyListForCustom(searchValue)">
          <el-button slot="append" icon="el-icon-search" type="primary"
            @click="searchCompanyListForCustom(searchValue)"></el-button>
        </el-input>
        <el-button size="small" type="primary" @click="addToCustomLink">关联至节点</el-button>
      </div>
      <el-table v-loading="customCompanyLoading" ref="multipleTable" :data="customCompanyData" tooltip-effect="dark"
        style="width: 100%" stripe @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="short_name" label="公司名" width="220">
        </el-table-column>
        <el-table-column prop="loc_province" label="地区" width="120">
        </el-table-column>
        <el-table-column prop="enterprises_type" label="企业类型" width="200">
        </el-table-column>
        <el-table-column prop="founded_time" label="成立时间" width="200">
        </el-table-column>
        <el-table-column label="名录标签">
          <template slot-scope="scope">
            <span class="labelItem" v-for="(item, index) in scope.row.allLabel" :key="index">
              {{ item }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <Favorites ref="favorites"></Favorites>
    <CompanyDetailHoriWithData ref="companyDetailHori"></CompanyDetailHoriWithData>
    <footer class="footer">
      <p class="footer-text">
        免责申明：本网站文章内容仅代表作者观点，信息服务基于人工智能算法生成，仅作为参考，读者自行综合分析，风险自担。
      </p>
      <p class="footer-text">
        Copyright © 深圳素问智能信息技术有限公司 粤ICP备19026970号-1
      </p>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import utils from "../utils/utils.js";
import {
  provinceAndCityDataPlus,
  regionDataPlus,
} from "element-china-area-data";
import IndustryBarVertical from "../components/IndustryBarVertical.vue";
import provincesAndCityMap from "@/json/provinceAndCityMap.json";
import CompanyInfo from "../components/CompanyInfo.vue";
import IndustryPie from "../components/IndustryPie.vue";
import TypePie from "@/components/TypePie.vue";
import areaMap from "@/json/areaMap.json";
import CompanyDetail from "../components/CompanyDetail.vue";
import CompanyDetailHori from "../components/CompanyDetailHori.vue";
import CompanyDetailHoriWithData from "../components/CompanyDetailHori.vue";
import NewsList from "@/components/NewsList.vue";
import PostTable from "@/components/PostTable.vue";
import PatentCardList from "@/components/PatentCardList.vue";
import Favorites from "@/components/Favorites.vue";

export default {
  name: "Home",
  components: {
    CompanyInfo,
    IndustryPie,
    TypePie,
    IndustryBarVertical,
    CompanyDetail,
    CompanyDetailHori,
    CompanyDetailHoriWithData,
    NewsList,
    PostTable,
    PatentCardList,
    Favorites,
  },
  data() {
    return {
      customClickData: [], // 我的产业链目前点击的节点数据
      companyList: [],
      multipleSelection: [],
      customCompanyLoading: false,
      customCompanyData: [],
      deleteCustomVisible: false,
      editCustomVisible: false,
      customType: 1, // 自定义的类型，1是根节点，2是后代节点
      customVisible: false,
      customName: "",
      customNode: [],
      customCurrentId: 1,
      customTmpData: {},
      subCompanyList: [],
      is_normal: 1,
      industryNodeSearchLoading: false,
      searchNodeList: [],
      searchNodeParams: {
        page: 1,
        page_size: 50,
        query: "",
        username: "",
      },
      searchNodeCount: 0,
      searchNodeValue: "",
      openeds: ["1"],
      subscribeNode: [], // 订阅的节点
      showStar: false,
      star_total: 0,
      starParams: {
        star_type: "",
        user: "",
        page: 1,
        page_size: 10,
      },
      starLoading: false,
      starNewsList: [],
      starPostList: [],
      starPatentList: [],
      starActive: "news",
      userTableData: [
        {
          perm_name: "测试",
          effective_time: "2022-03-01",
        },
      ],
      userActiveTab: "基本信息",
      newsShowLine: 0,
      patentRelateProduct: {},
      newsRelateProduct: {},
      postRelateProduct: {},
      patentTrendData: {},
      post_params: {
        industry: "",
        company_id: "",
        page: 1,
        page_size: 10,
        industry_node: "",
        post_classification: "",
        filter_type: "相关公司岗位",
      },
      wordCloudDataPatent: [],
      patent_listRival: [],
      radarTotal: 0,
      radarData: [0, 0, 0, 0, 0],
      companyNewsParams: {
        page: 1,
        page_size: 10,
        // company_id: '',
        company: "",
        domain_id: "",
        industry: "",
        news_type: "",
      },
      postCount: {
        // 岗位招聘排行
        legend: [],
        data: [],
        name: {},
      },
      newsListCompany: [],
      env: "",
      sideCollapse: false, // 侧边栏是否折叠
      select_value: "",
      industryImportantList: [],
      nowActive: "1",
      industryTreeLoading: false,
      companyListVisible: false,
      companyLoading: false,
      showInfo: false,
      radio: "",
      compNameForLogo: "",
      companyInfo: {
        entity_name: "",
        telephone: "",
        website: "",
        email: "",
        office_addr: "",
        description: "",
        label: "",
        legal_representative: "",
        company_type: "",
        founded_date: "",
        regis_capital: "",
        regis_code: "",
        headquater: "",
        employees: "",
        name_en: "",
        listed: "",
        domain: "",
        stock_code: "",
        industry: "",
        company_profile: "",
      },
      tagListHome: [],
      productList: [],
      industryPaquList: [],
      chartDataLink: {},
      chartLinkShortName: "",
      onlySub: false,
      chartDataFinancial: [],
      chartDataProduct: {},
      chartProductYearList: [],
      detailTrTopVal: [],
      patentEchartsData: [],
      wordCloudData2: {},
      patentListData: [],
      radarData: [],
      collapseActiveNames: ["1", "2", "3", "4", "5", "6"],
      patentParams: {
        industry: "ele_car",
        domain_id: "1",
        company_guogao_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
        inventor: "",
        company_id: "",
        applicant_id: "",
      },
      searchValue: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      activeIndex2: "1",
      first_node: 0,
      industryTree: [
        // {
        //   label: "动力电池",
        //   id: 1,
        //   children: [
        //     {
        //       label: "电池材料",
        //       id: 2,
        //     },
        //     {
        //       label: "电源管理系统",
        //       id: 3,
        //     },
        //     {
        //       label: "电池系统组件",
        //       id: 4,
        //     },
        //     {
        //       label: "充电模块",
        //       id: 5,
        //     },
        //   ],
        // }
      ],
      infoLoading: false,
      showUserTips: false,
      account: "",
      board_value: "",
      apiDataBarChart: {
        legend: [],
        data: [],
      },
      progressList: [],
      apiDataPieChart: [],
      apiDataPieChart2: [],
      companyInfoData: {},
      compNumCN: 0,
      searchProduct: "",
      sourceIndustryList: [],
      sourceIndustryList2: [],
      // index_industry: "",
      board_options: [
        {
          value: "深圳主板",
          label: "深圳主板",
        },
        {
          value: "深圳中小板",
          label: "深圳中小板",
        },
        {
          value: "深圳创业板",
          label: "深圳创业板",
        },
        {
          value: "上海主板",
          label: "上海主板",
        },
        {
          value: "上海科创板",
          label: "上海科创板",
        },
        {
          value: "新三板",
          label: "新三板",
        },
      ],
      tabIndex: 0,
      tabList: [
        {
          index: 0,
          name: "企业舆情",
          route: "/company_news",
        },
        // {
        //   index: 1,
        //   name: "产业舆情",
        //   route: "/industry_news"
        // },
        // {
        //   index: 2,
        //   name: "行业图谱",
        //   route: "/company_news"
        // },
        // {
        //   index: 4,
        //   name: "竞品分析",
        //   route: "/company_news"
        // },
        // {
        //   index: 5,
        //   name: "技术趋势",
        //   route: "/company_news"
        // }
      ],
      tagList: [],
      apiDataLineChart: [],
      updateLogVisible: false,
      logLoading: false,
      chartDataFinancial: [],
      chartDataProduct: {},
      chartDataLink: {},
      chartLinkShortName: "",
      chartProductYearList: [],
      collapseActiveNames: ["1", "2", "3", "4", "5"],
      provincesAndCitys: regionDataPlus,
      industryCountVisible: false,
      provinceCountVisible: false,
      graphChart: "",
      graphData: [],
      graphLink: [],
      checkAll: false,
      activeNames: "",
      checkedBoards: [],
      reportList: [],
      reportParams: {
        page: 1,
        page_size: 10,
      },
      reportPageTotal: 0,
      sourceIndustryValue: "",
      userTableData: [],
      boards: [
        "深圳主板",
        "深圳中小板",
        "深圳创业板",
        "上海主板",
        "上海科创板",
        "新三板",
      ],
      boards_map: [
        "深圳主板",
        "上海主板",
        "深圳中小板",
        "深圳创业板",
        "上海科创板",
        "新三板",
      ],
      isIndeterminate: false,
      // checkAll: false,
      // checkedBoards: ['上海', '北京'],
      // boards: ['上海', '北京', '广州', '深圳'],
      // isIndeterminate: true,
      updateLogList: [],
      trBasicVal: [],
      trFinVal: [],
      trTopVal: [],
      detailTrTopVal: [],
      showPersonalInfo: false,
      trProVal: [],
      boardList: [],
      userInfo: {
        username: "",
        company_name: "",
        post: "",
        email: "",
        telephone: "",
        effective_time: "",
        mobile: "",
        real_name: "",
      },
      userInfoParams: {
        username: "",
        company_name: "",
        post: "",
        email: "",
        telephone: "",
        token: "",
        effective_time: "",
        mobile: "",
        real_name: "",
      },
      lineChartFinancialVisible: false,
      lineChartProductVisible: false,
      isClick: true,
      board_id: 1,
      chartSelect2: 0,
      chartSelect: 0,
      globalOrChina: 1,
      exchangeMap: {
        // 交易所字段映射
        sh: "上海证券交易所",
        sz: "深圳证券交易所",
        neeq: "新三板",
      },
      formLabelAlign: {
        short_name: "",
        name: "",
        code: "",
        industry1: "",
        market_time: "",
        upstream_company: "欣旺达公司、腾讯公司、京东公司 ...",
        downstream_company: "欣旺达公司、腾讯公司、京东公司 ...",
        sub_company: "欣旺达公司、腾讯公司、京东公司 ...",
      },
      serverValue: "产业结构",
      serverOptionsTotal: [ // X.X 主页显示的列表
        // {
        //   value: "主页",
        //   label: "主页",
        //   route: "/firstPage",
        //   server: "kjld_zy",
        //   icon: "el-icon-s-home",
        //   index: "1",
        //   has_p: true,
        // },
        // {
        //   value: "产业生态",
        //   label: "产业生态",
        //   route: "/industry_structure",
        //   server: "kjld_cyst",
        //   icon: "el-icon-s-grid",
        //   index: "2",
        //   has_p: false
        // },
        {
          value: "产业图谱",
          label: "产业图谱",
          route: "/category_graph",
          server: "kjld_cytp",
          icon: "iconfont icon-tupu-01",
          index: "3",
          has_p: false,
        },
        {
          value: "产业搜索",
          label: "产业搜索",
          route: "/company_search",
          server: "kjld_qyss",
          icon: "el-icon-search",
          index: "7",
          has_p: false,
        },
        // {
        //   value: "产业图谱",
        //   label: "产业图谱",
        //   route: "/category_graph",
        //   server: "kjld_cytp",
        //   icon: "el-icon-share",
        //   index: "3"
        //   has_p: false
        // },
        // {
        //   value: "企业舆情",
        //   label: "企业舆情",
        //   route: "/company_news",
        //   server: "cyl_qyyq",
        //   has_p: false
        // },
        {
          value: "产业舆情",
          label: "产业舆情",
          route: "/industry_news",
          server: "kjld_cyyq",
          icon: "el-icon-notebook-2",
          index: "4",
          has_p: false,
        },
        //  {
        //    value: "产业招聘",
        //    label: "产业招聘",
        //    route: "/industry_talent",
        //    server: "kjld_gwjn",
        //    icon: "el-icon-s-help",
        //    index: "5",
        //    has_p: false,
        //  },
        {
          value: "产业专利",
          label: "产业专利",
          route: "/rival_patent",
          server: "kjld_zldc",
          icon: "el-icon-view",
          index: "6",
          has_p: false,
        },
        //  {
        //    value: "产业人才",
        //    label: "产业人才",
        //    route: "/industry_person",
        //    server: "kjld_zldc",
        //    icon: "iconfont icon-rencaifuwu",
        //    index: "8",
        //    has_p: false,
        //  },
        {
          value: "产业图谱  >  企业图谱",
          label: "企业图谱",
          route: "/company_info",
          server: "kjld_qytp",
          icon: "iconfont icon-rencaifuwu",
          index: "9",
          has_p: false,
        },
      ],
      progressTotal: 0,
      serverOptions: [],
      industryOptions: [
        {
          value: " ",
          label: "全部行业",
        },
        {
          value: "农、林、牧、渔业",
          label: "农、林、牧、渔业",
        },
        {
          value: "采矿业",
          label: "采矿业",
        },
        {
          value: "制造业",
          label: "制造业",
        },
        {
          value: "电力、热力、燃气及水生产和供应业",
          label: "电力、热力、燃气及水生产和供应业",
        },
        {
          value: "建筑业",
          label: "建筑业",
        },
        {
          value: "批发和零售业",
          label: "批发和零售业",
        },
        {
          value: "交通运输、仓储和邮政业",
          label: "交通运输、仓储和邮政业",
        },
        {
          value: "住宿和餐饮业",
          label: "住宿和餐饮业",
        },
        {
          value: "信息传输、软件和信息技术服务业",
          label: "信息传输、软件和信息技术服务业",
        },
        {
          value: "金融业",
          label: "金融业",
        },
        {
          value: "房地产业",
          label: "房地产业",
        },
        {
          value: "租赁和商务服务业",
          label: "租赁和商务服务业",
        },
        {
          value: "科学研究和技术服务业",
          label: "科学研究和技术服务业",
        },
        {
          value: "水利、环境和公共设施管理业",
          label: "水利、环境和公共设施管理业",
        },
        {
          value: "居民服务、修理和其他服务业",
          label: "居民服务、修理和其他服务业",
        },
        {
          value: "教育",
          label: "教育",
        },
        {
          value: "卫生和社会工作",
          label: "卫生和社会工作",
        },
        {
          value: "文化、体育和娱乐业",
          label: "文化、体育和娱乐业",
        },
        {
          value: "综合",
          label: "综合",
        },
      ],
      type_value: "",
      type_options: [
        {
          value: " ",
          label: "全部类型",
        },
        {
          value: "0",
          label: "上市企业",
        },
        {
          value: "1",
          label: "规上企业",
        },
        {
          value: "2",
          label: "重点企业",
        },
      ],
      value: "",
      company_id_selected: "0",
      compList: [],
      industryList: [],
      industryListForBar: [],
      sourceActiveName: "1",
      newsActiveName: "3",
      loading: false,
      dialogVisible: false,
      pdfTopDetailVisible: false,
      pdfDetailVisible: false,
      companyInfoVisible: false,
      permissionModule: ["企业舆情", "产业舆情", "产业风险"],
      deadline: "2021-08-18 12:00:00",
      newsDetail: {
        title: "",
        release_time: "",
        source: "",
        content: "",
        url: "",
        web_source: "",
      },
      pdfDetail: {},
      pdfTopDetail: {},
      form: {
        source_name_zh: "",
        source_name: "",
        source_type: "0",
        website: "",
      },
      sourceList: [],
      newsList: [],
      companyNewsList: [],
      listedNewsList: [],
      listType: "patentList",
      companyParams: {
        permissions: "",
        page: 1,
        page_size: 10,
        keyword: "",
        industry1: "",
        area: [],
        product: "",
        focus: "",
        industry_level: "",
        industry_node: "",
      },
      industryParams: {
        page: 1,
        page_size: 10,
      },
      industryTotal: 0,
      companyPageTotal: 0,
      newsParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        source_type: "",
      },
      newsPageTotal: 0,
      companyNewsPageTotal: 0,
      requestParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        source_type: "1", // 财报类型选择:0 => 半年度，1 => 年度
      },
      logParams: {
        page: 1,
        page_size: 20,
        name: "",
        date: "",
      },
      logPageTotal: 0,
      pageTotal: 0,
      is_customIndustry: false, // 自定义产业链权限
      is_custom: false, // 是否是自定义产业链发起的事件
      isIndustrialMap: false, // 判断当前服务是否是产业图谱
    };
  },
  computed: {
    index_industry() {
      return this.$store.getters.index_industry;
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        console.log("route", newVal);
        this.serverOptionsTotal.forEach((i) => {
          if (newVal.path.indexOf(i.route) != -1) {
            this.nowActive = i.index;
          }
        });
        let default_industry = this.industryImportantList[0];
        let index_industry = sessionStorage.getItem("index_industry")
          ? JSON.parse(sessionStorage.getItem("index_industry"))
          : default_industry;
        if (oldVal == "/index") {
          // this.getIndustryTree(index_industry.name);
          this.index_industry = index_industry.name;
        }
        if (this.index_industry == "") {
          this.$store.commit("changeIndexIndustry", "锂电池正极材料");
        }
        // if (newVal.path == '/category_graph' && !newVal.query.from) {
        //   console.log("直接进入产业图谱")
        //   this.$store.commit("changeIndexIndustry", default_industry.name)
        //   sessionStorage.setItem("index_industry", JSON.stringify(default_industry))
        //   console.log("直接进入产业图谱", this.index_industry)
        // }
        this.serverOptionsTotal.forEach((i) => {
          if (i.route == newVal.path) {
            this.serverValue = i.value;
            console.log("this.serverValue", this.serverValue);
          }
        });
      },
    },
    index_industry: {
      handler(newVal, oldVal) {
        console.log("监听index_industry");
        console.log("@@@index_industry", newVal);
        this.index_industry = newVal;
        // this.select_value = newVal;
        // this.getIndustryTree(newVal);
        // EventBus.$emit("tree_click", this.industryTree[0])
      },
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addToCustomLink() {
      // console.log("customData", this.customTmpData)
      // console.log("multipleSelection", this.multipleSelection)
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请先勾选公司！");
        return;
      }
      let params = {
        node_id: this.customTmpData.id,
        company_id_list: [],
      };
      this.multipleSelection.forEach((m) => {
        params.company_id_list.push(m.id);
      });
      console.log("params", params);
      api.customAddCompany(params).then((res) => {
        this.$message.success(res.msg);
      });
    },
    searchCompanyListForCustom(searchValue) {
      let params = {
        keyword: searchValue,
      };
      this.customCompanyLoading = true;
      api.searchCustomCompany(params).then((res) => {
        res.data.forEach((i) => {
          if (i.short_name) {
            i.name = i.short_name;
          } else {
            i.name = i.full_name;
          }
          //合并名录标签
          i.allLabel = i.directory_label + "；" + i.company_type_tags;
          // console.log('allLabel',i.allLabel)
          i.allLabel = i.allLabel.replaceAll("null", "").replaceAll("；", " ");
          //标签去重
          i.allLabel = Array.from(new Set(i.allLabel.split(" ")));
          if (i.allLabel[0] == "") {
            i.allLabel.shift();
          }
          if (i.allLabel[i.allLabel.length - 1] == "") {
            i.allLabel.pop();
          }
          // .toString().replaceAll(',',' ')
          if (i.founded_time != null) {
            i.founded_time = i.founded_time.split("T")[0];
          }
          this.customCompanyData = res.data;
        });
        this.customCompanyLoading = false;
      });
    },
    handleDeleteCustom() { },
    // 获取自定义节点
    getCustomNode() {
      let params = {
        account: cookie.getCookie("account"),
      };
      api.getCustomNode(params).then((res) => {
        this.customNode = res.data;
        console.log("自定义节点：", res);
      });
    },
    addCustomNode() {
      this.customVisible = true;
      this.customType = 1;
    },
    handleAddCustomNode() {
      if (this.customType == 1) {
        // 新增父节点
        let params = {
          father_id: -1,
          account: cookie.getCookie("account"),
          level: 1,
          name: this.customName,
        };
        api.addCustomNode(params).then((res) => {
          if (res.status == 200) {
            // this.customNode = [
            //   {
            //     id: res.node_id,
            //     label: this.customName,
            //     level: 1,
            //     children: []
            //   }
            // ]
            this.getCustomNode();
            this.$message.success("添加节点成功！");
          } else {
            console.log("添加节点错误", res);
          }
          this.customVisible = false;
          this.customName = "";
        });
      } else {
        // 新增后代节点
        let params = {
          father_id: this.customTmpData.id,
          account: cookie.getCookie("account"),
          level: this.customTmpData.level + 1,
          name: this.customName,
        };
        api.addCustomNode(params).then((res) => {
          if (res.status == 200) {
            const newChild = {
              id: res.node_id,
              level: this.customTmpData.level + 1,
              label: this.customName,
              children: [],
            };
            if (!this.customTmpData.children) {
              this.$set(this.customTmpData, "children", []);
            }
            this.customTmpData.children.push(newChild);
          }
          // this.customCurrentId += 1
          this.customVisible = false;
          this.customName = "";
          this.$message.success("添加节点成功！");
        });
      }
    },
    // 自定义节点——增
    append(data) {
      this.customTmpData = data;
      this.customType = 2;
      this.customVisible = true;
      // const newChild = { id: 2, label: 'testtest', children: [] };
      // if (!data.children) {
      //   this.$set(data, 'children', []);
      // }
      // data.children.push(newChild);
    },
    // 自定义节点——删
    remove(node, data) {
      console.log("删除节点node", node);
      console.log("删除节点data", data);
      let params = {
        account: cookie.getCookie("account"),
        node_id: data.id,
      };
      api.deleteCustomNode(params).then((res) => {
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex((d) => d.id === data.id);
        children.splice(index, 1);
        this.$message.success(res.msg);
      });
    },
    // 自定义节点——编辑
    edit(data) {
      this.editCustomVisible = true;
      this.customTmpData = data;
    },
    // 点击产业链顶部搜索框的内容
    handleSelectSearch(val) {
      console.log("点击产业链顶部搜索框的内容", val);
      val.id = val.node_id;
      let current_server = "";
      this.serverOptions.forEach((s) => {
        if (this.$route.path.indexOf(s.route) != -1) {
          current_server = s.server;
        }
      });
      let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
      let has_perm = false;
      vip_perm.forEach((v) => {
        if (v.codename == current_server) {
          has_perm = v.has_perm;
        }
      });
      console.log(has_perm);
      if (has_perm) {
        this.searchNodeList = [];
        this.selectIndustry(val);
      } else {
        if (val.clickable == true) {
          this.searchNodeList = [];
          this.selectIndustry(val);
        } else {
          this.$message.warning(
            "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
          );
        }
      }
    },
    // 点击模块标题
    handleClickServerTitle(val) {
      console.warn("点击标题返回");
      if (val == "产业图谱  >  企业图谱") {
        // EventBus.$emit("clickToFirst", 1);
        console.log("点击标题返回2222222");
        this.$router.go(-1);
      }
      if (val == "产业图谱" && this.is_custom) {
        // EventBus.$emit("clickToFirst", 1);
        // EventBus.$emit("handleClickDiyTab", "首页")
        EventBus.$emit("clickToFirst");
        this.is_custom = false;
      }
    },
    // 加载更多搜索产业节点
    showMoreIndustryNode() {
      this.searchNodeParams.page += 1;
      this.industryNodeSearch();
    },
    // 产业链搜索应用方法
    handleNodeSearch() {
      this.searchNodeList = [];
      if (this.searchNodeParams.query) {
        this.searchNodeParams.page = 1;
        this.industryNodeSearch();
      }
    },
    // 产业链节点搜索
    async querySearchAsync(query, cb) {
      if (this.searchNodeParams.query) {
        this.searchNodeParams.page = 1;
        this.searchNodeList = [];
        // let res = await this.industryNodeSearch()
        // await console.log("querySearchAsync", this.searchNodeList)
        api.industryNodeSearch(this.searchNodeParams).then((res) => {
          console.log("产业链搜索111", res);
          // this.searchNodeList = res.data
          res.data.forEach((r) => {
            r.value = r.name;
            this.searchNodeList.push(r);
          });
          this.searchNodeCount = res.count;
          this.industryNodeSearchLoading = false;
          // return this.searchNodeList
          cb(this.searchNodeList);
        });
        // let res = [
        //   { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
        //   { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
        //   { "value": "阳阳麻辣烫", "address": "天山西路389号" },
        //   { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
        // ]
        // await cb(res)
      } else {
        cb([]);
      }
    },
    // 产业链搜索基础方法
    industryNodeSearch() {
      this.industryNodeSearchLoading = true;
      api.industryNodeSearch(this.searchNodeParams).then((res) => {
        console.log("产业链搜索222", res);
        // this.searchNodeList = res.data
        res.data.forEach((r) => {
          r.value = r.name;
          this.searchNodeList.push(r);
        });
        this.searchNodeCount = res.count;
        this.industryNodeSearchLoading = false;
        return this.searchNodeList;
      });
    },
    // 获取某元素在数组中的下标
    getArrIndex(arr, attr, val) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][attr] == val) return i;
      }
      return -1;
    },
    // 删除数组的某元素
    arrRemove(arr, attr, val) {
      let index = this.getArrIndex(arr, attr, val);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    // 监听修改自定义节点名称
    listenChangeNodeName() {
      +EventBus.$on("changeNodeName", (data) => {
        this.getCustomNode();
        this.handleNodeClick(this.customClickData);
      });
    },
    // 监听取消订阅
    listenCancelSubscribe() {
      EventBus.$on("cancelSubscribe", (data) => {
        // this.arrRemove(this.subscribeNode, "name", data);
        this.getUserSubscribe();
      });
    },
    // 监听添加订阅
    listenAddUserSubscribe() {
      EventBus.$on("addUserSubscribe", (data) => {
        // this.subscribeNode.push(data);
        this.getUserSubscribe();
      });
    },
    // 获取用户订阅
    getUserSubscribe() {
      let params = {
        account: cookie.getCookie("account"),
        subscribe_type: "option",
      };
      api
        .getUserSubscribe(params)
        .then((res) => {
          this.subscribeNode = res.data.filter(
            (x) => x.subscribe_type == "option"
          );
          let subscribeNodeName = [];
          this.subscribeNode.forEach((s) => {
            subscribeNodeName.push(s["name"]);
          });
          sessionStorage.setItem(
            "subscribeNodeName",
            JSON.stringify(subscribeNodeName)
          );
          EventBus.$emit("updateSubscribe", 1);
        })
        .catch((err) => { });
    },
    selectIndustry(val) {
      console.log("点击产业链(非树非下拉)", val);
      val.is_search = true;
      this.is_custom = false;
      if (val.root_id == "" || val.root_id == undefined) {
        // 判断为根节点(indusrty_domain里is_root=1)
        val.root_name = val.name;
        this.$store.commit("changeIndexIndustry", val.name);
        EventBus.$emit("industry_drop", val);
      } else {
        this.$store.commit("changeIndexIndustry", val.name);
        EventBus.$emit("industry_drop", val);
      }
    },
    // 点击个人中心
    handleClickPersonal() {
      // let user = cookie.getCookie("account")
      // this.$refs.favorites.showFavorites(user)
      this.serverValue = "个人中心";
      cookie.setCookie("serverValue", "个人中心");
      cookie.setCookie("serverIndex", null);
      this.nowActive = null;
      this.$router.push("/personal");
    },
    handlePostPageChange(val) {
      console.log(val);
    },
    // 响应点击收藏tab
    handleStarActive(val) {
      console.log(val.name);
      this.starParams.page = 1;
      this.starParams.star_type = val.name;
      this.getUserStar();
    },
    // 获取我的收藏
    getUserStar() {
      this.starLoading = true;
      api
        .getUserStar(this.starParams)
        .then((res) => {
          console.log(res);
          if (this.starParams.star_type == "news") {
            this.starNewsList = res.data;
          }
          if (this.starParams.star_type == "post") {
            this.starPostList = res.data;
          }
          if (this.starParams.star_type == "patent") {
            this.starPatentList = res.data;
          }
          this.star_total = res.count;
          this.starLoading = false;
        })
        .catch((error) => {
          this.starLoading = false;
          this.$message.error(error);
        });
    },
    handleShowMoreNews() {
      this.companyNewsParams.page += 1;
      this.getCompanyNews();
    },
    handleShowMorePost() {
      this.post_params.page += 1;
      this.getPostInfo();
    },
    handleShowMorePatentRival() {
      this.patentParams.page += 1;
      this.getIndustryPatentRival();
    },
    // 获取公司专利趋势
    getCompanyPatentTrend(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getCompanyPatentTrend(params).then((res) => {
        console.log("获取公司专利趋势", res);
        this.patentTrendData = res.data;
      });
    },
    // 获取企业舆情
    getCompanyNews() {
      console.log("-----------------------获取产业舆情");
      // this.companys = []
      api.getCompanyNews(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        res.data.forEach((i) => {
          let company_list = [];
          i.company_list.forEach((c) => {
            company_list.push(c);
          });
          // i.company_list_arr = [...new Set(company_list)]
          // i.company_list_arr = this.unique(company_list, "short_name");
          i.company_list_arr_total = this.unique(company_list, "short_name");
          i.company_list_arr = i.company_list_arr_total.slice(0, 10);
          if (i.company_list_arr_total.length > 10) {
            i.company_list_arr.push({
              short_name: "...",
            });
          }
          // console.log("company_list", company_list)
          // console.log("title_classify_tags", i.title_classify_tags)
          i.title_classify_tags.forEach((t, index) => {
            if (
              index > 0 &&
              index % 2 == 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 2]
            ) {
              i.title_classify_tags[index] = "";
            }
            if (
              index % 2 != 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 1]
            ) {
              i.title_classify_tags[index] = "";
            }
          });
          this.newsListCompany.push(i);
        });
      });
    },
    // 选择下拉
    handleChangeSelect(val) {
      let current_server = "";
      this.serverOptions.forEach((s) => {
        if (this.$route.path.indexOf(s.route) != -1) {
          current_server = s.server;
        }
      });
      console.log("current_server", current_server);
      let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
      let has_perm = false;
      vip_perm.forEach((v) => {
        if (v.codename == current_server) {
          has_perm = v.has_perm;
        }
      });
      console.log("has_perm", has_perm);
      console.log("选择下拉", val);
      this.customStatusChange();
      let item = {};
      this.industryImportantList.forEach((i) => {
        if (i.name == val) {
          item = i;
        }
      });
      if (has_perm) {
        this.$store.commit("changeIndexIndustry", val);
        console.log("handleChangeSelect111", val);
        // todo
        this.getIndustryTree(item);
        item.is_drop = true; // 标识是选择下拉
        console.log("item", item);
        EventBus.$emit("industry_drop", item);
      } else {
        if (item.clickable == true) {
          this.$store.commit("changeIndexIndustry", val);
          console.log("handleChangeSelect222", item);
          this.getIndustryTree(item);
          item.is_drop = true; // 标识是选择下拉
          console.log("item", item);
          EventBus.$emit("industry_drop", item);
        } else {
          this.$message.warning(
            "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
          );
        }
      }
    },
    // 选择侧边菜单
    handleSelect(val) {
      console.log("选择侧边菜单", val);
      console.log("nowActive", this.nowActive);
    },
    chooseMenu(val) {
      console.log("chooseMenu", val);
    },
    // 获取公司详情信息
    async getCompanyInfo(row) {
      console.log("val", row);
      let params = {
        full_name: row.full_name,
      };
      this.radarData = [
        row.scale,
        row.technology,
        row.customer,
        row.brand,
        // row.risk + 100
        row.vitality,
      ];
      this.radarTotal = row.comprehensive;
      console.log("radarData", this.radarData);
      api.getCompanyInfo(params).then((res) => {
        console.log("公司信息", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.legal_representative = info.representative;
        this.companyInfo.company_type = info.enterprises_type;
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        this.companyInfo.domain = info.business_scope;
        this.companyInfo.industry = info.industry;
        this.companyInfo.company_profile = info.company_profile;
        this.compNameForLogo = info.short_name
          ? info.short_name.substring(0, 4)
          : info.full_name.substring(3, 7);
        this.chartLinkShortName = info.short_name
          ? info.short_name
          : info.full_name;
        this.companyNewsParams.page = 1;
        this.companyNewsParams.company = info.short_name;
        this.newsListCompany = [];
        this.post_list = [];
        this.getCompanyNewsByProduct();
        this.showInfo = true;
      });
      let company_id = await api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
      let company_guogao_id = await api
        .getCompanyGuogaoId(params)
        .then((res) => {
          console.log("获取公司company_guogao_id", res.data);
          return res.data;
        });
      this.patentListData = [];
      this.patentParams.company_id = company_id;
      this.getProductTag(company_id);
      this.getLink(company_id);
      this.getFinancialAndProductInfo(company_id);
      this.getHolder(company_id);
      this.getPatentCountAndKeyword(company_id);
      this.getPostMonthRank(company_id);
      this.getPostCount(company_id);
      this.getIndustryPatentKeyword(company_guogao_id);
      this.getIndustryPatentRival(company_id);
      this.getCompanyPatentTrend(company_id);
      this.post_params.company_id = company_id;
      this.getPostInfo();
      this.getPatentList();
      this.getCompanyRelatedProducts(company_id, "patent");
      this.getCompanyRelatedProducts(company_id, "post");
      this.getCompanyRelatedProducts(company_id, "news");
    },
    // 获取企业舆情（按照产品）
    getCompanyNewsByProduct() {
      api.getCompanyNewsByProduct(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        this.newsListCompany = res.data;
      });
    },
    // 获取公司招聘信息
    getPostInfo() {
      api
        .getPostInfo(this.post_params)
        .then((res) => {
          console.log("获取公司招聘信息", res.data);
          // this.post_list = res.data;
          res.data.forEach((i) => {
            this.post_list.push(i);
          });
          // this.post_total = res.count;
        })
        .catch((err) => { });
    },
    // 获取获取舆情、招聘、专利相关产品关系图
    getCompanyRelatedProducts(company_id, filter_type) {
      let params = {
        company_id: company_id,
        filter_type: filter_type,
      };
      api.getCompanyRelatedProducts(params).then((res) => {
        console.log("获取获取舆情、招聘、专利相关产品关系图", res);
        if (filter_type == "news") {
          this.newsRelateProduct = res.data;
        } else if (filter_type == "patent") {
          this.patentRelateProduct = res.data;
        } else if (filter_type == "post") {
          this.postRelateProduct = res.data;
        }
      });
    },
    // 获取产业专利
    getIndustryPatentRival(company_id) {
      // this.patent_list = []
      this.patentParams.company_id = company_id;
      api
        .getIndustryPatent(this.patentParams)
        .then(async (res) => {
          console.log("产业专利", res);
          await res.data.forEach(async (r) => {
            // r.has_graph = await api
            //   .getPatentStructure({
            //     patent_id: r.id,
            //   })
            //   .then((res) => {
            //     console.log("获取专利结构", res);
            //     // if (res.data.structure_node.length == 0) {
            //     //   return res.data
            //     // } else {
            //     //   return res.data
            //     // }
            //     return res.data
            //   })
            // console.log("r", r)
            this.patent_listRival.push(r);
          });
          console.log("patent_list", this.patent_listRival);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword(company_guogao_id) {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: company_guogao_id,
        filter_type: this.patentParams.filter_type,
      };
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudDataPatent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取招聘岗位统计
    getPostCount(company_id) {
      this.postCount.legend = [];
      this.postCount.data = [];
      this.postCount.name = {};
      let params = {
        industry: "",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
        filter_type: "相关公司岗位",
      };
      api
        .getPostCount(params)
        .then((res) => {
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
          });
        })
        .catch((err) => { });
    },
    // 获取招聘月度排行
    getPostMonthRank(company_id) {
      let params = {
        industry: "",
        filter_type: "相关公司岗位",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
      };
      api.getPostMonthRank(params).then((res) => {
        // console.log("月度排行", res)
        this.talentEchartsData = res.data;
      });
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach((i) => {
          this.patentListData.push(i);
        });
        // this.patentLoading = false;
      });
    },
    // 获取专利统计和关键词云
    getPatentCountAndKeyword(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.patentLoading = true
      api
        .getCompanyBaseInfo(params)
        .then((res) => {
          console.log("专利", res);
          this.patentEchartsData = res.data[0].year_count_list;
          this.wordCloudData2 = res.data[0].patent_keywords;
          // this.patentLoading = false
        })
        .catch((err) => {
          // this.patentLoading = false
        });
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          // this.$message({
          //   message: err,
          //   type: "error",
          // });
        });
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          if (res.data.length != 0) {
            this.lineChartFinancialVisible = true;
          } else {
            this.lineChartFinancialVisible = false;
          }
        })
        .catch((err) => {
          // this.loading = false;
          // console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
        if (res.year_list.length != 0) {
          this.lineChartProductVisible = true;
        } else {
          this.lineChartProductVisible = false;
        }
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
        })
        .catch((err) => {
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagListHome = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      // this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagListHome = res.data.product_detail.split("；");
            this.tagListHome.pop();
            this.tagListHome = [...new Set(this.tagListHome)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }

          // this.tagLoading = false;
        })
        .catch((err) => {
          // this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    showMorePatent() {
      this.patentParams.page += 1;
      console.log("page", this.patentParams.page);
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach((i) => {
          this.patentListData.push(i);
        });
        // this.patentLoading = false;
      });
    },
    // 搜索框搜索公司
    searchCompanyList(val) {
      this.radio = "";
      let params = {
        keyword: val,
      };
      this.companyListVisible = true;
      this.companyLoading = true;
      api.getCompanyList(params).then((res) => {
        console.log("搜索框搜索公司", res);
        this.companyList = res.data;
        this.companyLoading = false;
      });
    },
    // 单选框选择
    handleChangeRadio(val) {
      console.log("单选框选择", val);
      let params = {
        full_name: val,
        // scale: 80,
        // technology: 80,
        // customer: 80,
        // brand: 80,
        // vitality: 80
      };
      this.companyListVisible = false;
      let selectCompanyParams = {
        full_name: val,
        id: this.companyList.filter((x) => x.full_name == val).id,
      };
      // this.$refs.companyDetailHori.selectCompany(selectCompanyParams);
      let server_permission_str = cookie.getCookie("server");
      let server_permission = JSON.parse(server_permission_str);
      let flag = 0;
      server_permission.forEach((s) => {
        if (s.codename == "kjld_qytp" && s.has_perm == true) {
          console.log("选择公司", selectCompanyParams);
          // this.$refs.companyDetailHori.selectCompany(row);
          this.$router.push(
            "/company_info?row=" + JSON.stringify(selectCompanyParams)
          );
          flag = 1;
          this.$nextTick(() => {
            EventBus.$emit(
              "selectCompanyInfo",
              JSON.stringify(selectCompanyParams)
            );
          });
        }
      });
      if (flag == 0) {
        this.$message.warning(
          "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
        );
      }
    },

    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // 文本裁短
    cutText(str) {
      if (str.length >= 8) {
        return str.slice(0, 8) + "...";
      } else {
        return str;
      }
    },
    // 反爬方法
    confuseSpider(item_id, name) {
      let value_arr = [];
      for (let m = 0; m < 3; m++) {
        value_arr.push(
          `<span id="linkName">${utils.randomChineseString(6)}</span>`
        );
      }
      value_arr.splice(
        this.newsShowLine - 1,
        1,
        `<span id="${item_id}">${name}</span>`
      );
      let value_fake = "";
      value_arr.forEach((t) => {
        value_fake += t;
      });
      // console.log("value_fake", value_fake)
      return value_fake;
    },
    // 获取产业树
    getIndustryTree(val) {
      console.log("%%%%% ", val);
      let params = {
        root_id: val.domain_id,
        username: cookie.getCookie("account"),
      };
      this.treeLoading = true;
      api
        .getIndustryTree(params)
        .then(async (res) => {
          let treeData = [];
          res.data.nodes.forEach((i) => {
            if (i.name == val.name) {
              this.first_node = i.id;
              treeData.push({
                // label: this.cutText(val.name),
                name: i.name,
                clickable: i.clickable,
                label: this.confuseSpider("treeName", this.cutText(val.name)),
                node_id: i.id,
                children: [],
              });
            }
          });
          res.data.nodes.forEach((i) => {
            if (i.father_id == treeData[0].node_id) {
              treeData[0].children.push({
                // label: this.cutText(i.name),
                name: i.name,
                clickable: i.clickable,
                label: this.confuseSpider("treeName", this.cutText(i.name)),
                node_id: i.id,
                children: [],
              });
            }
          });
          res.data.nodes.forEach((i) => {
            treeData[0].children.forEach((t) => {
              if (i.father_id == t.node_id) {
                t.children.push({
                  // label: this.cutText(i.name),
                  name: i.name,
                  clickable: i.clickable,
                  label: this.confuseSpider("treeName", this.cutText(i.name)),
                  node_id: i.id,
                  children: [],
                });
              }
            });
          });
          treeData[0].children.forEach((t) => {
            t.children.forEach((c) => {
              res.data.nodes.forEach((i) => {
                if (i.father_id == c.node_id) {
                  c.children.push({
                    name: i.name,
                    clickable: i.clickable,
                    // label: this.cutText(i.name),
                    label: this.confuseSpider("treeName", this.cutText(i.name)),
                    node_id: i.id,
                    children: [],
                  });
                }
              });
            });
          });
          treeData[0].children.forEach((t) => {
            t.children.forEach((c) => {
              c.children.forEach((cc) => {
                res.data.nodes.forEach((i) => {
                  if (i.father_id == cc.node_id) {
                    cc.children.push({
                      name: i.name,
                      clickable: i.clickable,
                      // label: this.cutText(i.name),
                      label: this.confuseSpider(
                        "treeName",
                        this.cutText(i.name)
                      ),
                      node_id: i.id,
                      children: [],
                    });
                  }
                });
              });
            });
          });
          treeData[0].children.forEach((t) => {
            t.children.forEach((c) => {
              c.children.forEach((cc) => {
                cc.children.forEach((ccc) => {
                  res.data.nodes.forEach((i) => {
                    if (i.father_id == ccc.node_id) {
                      ccc.children.push({
                        name: i.name,
                        clickable: i.clickable,
                        // label: this.cutText(i.name),
                        label: this.confuseSpider(
                          "treeName",
                          this.cutText(i.name)
                        ),
                        node_id: i.id,
                        children: [],
                      });
                    }
                  });
                });
              });
            });
          });
          console.log("==========获取产业树的数据=========");
          console.log("treeData", treeData);
          this.industryTree = treeData;
          // EventBus.$emit("tree_click", this.industryTree[0]);
          // console.log("industryTree", this.industryTree[0]);
          this.treeLoading = false;
        })
        .catch((err) => {
          console.log("报错了");
          // this.treeLoading = false;
          this.treeLoading = false;
        });
    },
    customStatusChange() {
      this.is_custom = false;
    },
    handleNodeClick(data) {
      console.warn("点击树", data);
      this.is_custom = false;
      if (data.is_company == 1) {
        console.log("set is_company cookie");
        cookie.setCookie("is_company", "1");
      } else {
        console.log("del is_company cookie");
        cookie.delCookie("is_company");
      }
      if (Object.keys(data).includes("is_custom")) {
        // 判断是否是自定义产业链发起的节点点击事件
        this.is_custom = true;
      }
      let current_server = "";
      this.serverOptions.forEach((s) => {
        if (this.$route.path.indexOf(s.route) != -1) {
          current_server = s.server;
        }
      });
      console.log("current_server", current_server);
      let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
      console.log("vip_perm", vip_perm);
      let has_perm = false;
      vip_perm.forEach((v) => {
        if (v.codename == current_server) {
          has_perm = v.has_perm;
        }
      });
      console.log("has_perm", has_perm);
      this.customClickData = data;
      if (has_perm) {
        EventBus.$emit("tree_click", data);
      } else {
        if (data.clickable == true) {
          EventBus.$emit("tree_click", data);
        } else {
          this.$message.warning(
            "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
          );
        }
      }
    },
    // 返回首页
    toFirst() {
      this.nowActive = "0";
      this.$nextTick(() => {
        this.nowActive = "1";
      });
      this.$router.push("/category_graph");
      console.log("toFirst", this.nowActive);
    },
    // 监听行业图谱产业构成数据
    listenIndustryComposition() {
      EventBus.$on("industry_composition", (data) => {
        this.progressTotal = 0;
        this.progressList = [];
        data.forEach((d) => {
          this.progressList.push({
            name: d.name,
            count: d.num,
          });
          this.progressTotal += d.num;
        });
      });
    },
    // 获取公司基本信息
    getCompanyBase(id) {
      let params = {
        id: id,
      };
      this.infoLoading = true;
      api
        .getCompanyBase(params)
        .then((res) => {
          console.log("公司基本信息", res);
          this.formLabelAlign.short_name = res.data.short_name;
          this.formLabelAlign.name = res.data.full_name;
          this.formLabelAlign.board_id = res.data.board_id;
          this.formLabelAlign.industry1 = res.data.industry1;
          this.formLabelAlign.code = res.data.code;
          this.formLabelAlign.market_time = res.data.market_time
            ? res.data.market_time.replace("T00:00:00Z", "")
            : "暂无";
          this.formLabelAlign.founded_time = res.data.founded_time
            ? res.data.founded_time.replace("T00:00:00Z", "")
            : "暂无";
          this.formLabelAlign.address = res.data.address
            ? res.data.address
            : "暂无";
          this.formLabelAlign.registered_capital = res.data.registered_capital
            ? res.data.registered_capital
            : "暂无";
          this.companyInfoData = this.formLabelAlign;
          this.infoLoading = false;
        })
        .catch((err) => {
          this.infoLoading = false;
        });
    },
    // 点击个人中心
    handleUserInfo() {
      this.userInfoParams = JSON.parse(JSON.stringify(this.userInfo));
      this.showPersonalInfo = true;
      this.userActiveTab = "基本信息";
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        username: cookie.getCookie("account"),
        token: cookie.getCookie("token"),
      };
      console.log("用户信息参数", params);
      api.getUserInfo(params).then((res) => {
        console.log("用户信息", res);
        this.userTableData = res.user_info.permissions_info;
        this.userInfo.username = res.user_info.username;
        this.userInfo.company_name = res.user_info.company_name;
        this.userInfo.post = res.user_info.post;
        this.userInfo.email = res.user_info.email;
        this.userInfo.telephone = res.user_info.telephone;
        this.userInfo.mobile = res.user_info.mobile;
        this.userInfo.real_name = res.user_info.real_name;
        this.userInfo.effective_time = res.user_info.effective_time
          ? res.user_info.effective_time.replace("T00:00:00", "")
          : "";
        this.showUserTips = !Object.keys(res.user_info).every((val) => {
          return res.user_info[val];
        });
        // console.log("flag", flag)
      });
    },
    // 修改用户信息
    changeUserInfo() {
      if (!this.userInfoParams.company_name) {
        this.$message.warning("企业名未填");
        return;
      } else if (!this.userInfoParams.post) {
        this.$message.warning("岗位未填");
        return;
      } else if (!this.userInfoParams.mobile) {
        this.$message.warning("电话未填");
        return;
      }
      this.userInfoParams.token = cookie.getCookie("token");
      // console.log("修改用户信息", this.userInfoParams)
      api.changeUserInfo(this.userInfoParams).then((res) => {
        console.log("修改用户信息", res);
        if (res.code == 200) {
          this.$message.success("修改用户信息成功！");
          this.getUserInfo();
        } else {
          this.$message.error(`修改用户信息失败！, ${res.errmsg}`);
        }
      });
    },
    // 获取产业类型列表
    getNewsIndustry() {
      let params = {
        permissions: this.companyParams.permissions,
      };
      api.getNewsIndustry(params).then((res) => {
        this.sourceIndustryList = res.data;
      });
    },
    progressColor(count) {
      if (count >= 10) {
        return "#67C23A";
      } else if (count >= 5 && count < 10) {
        return "#E6A23C";
      } else {
        return "#F56C6C";
      }
    },
    format(percentage) {
      return Math.ceil(((percentage / 100) * this.progressTotal).toFixed(2));
    },
    // 获取产业图谱选项
    getIndustryGraphOptionRadar() {
      this.sourceIndustryList2 = [];
      this.industryTreeLoading = true;
      let params = {
        username: cookie.getCookie("account"),
      };
      api
        .getIndustryGraphOptionRadar(params)
        .then((res) => {
          console.log("getIndustryGraphOptionRadar", res);
          // let industry_graph = cookie.getCookie("industry_graph");
          let industry_graph = JSON.parse(
            sessionStorage.getItem("industry_graph")
          );
          // let industry_graph_array = industry_graph.split(",");
          let industry_graph_array = [];
          industry_graph.forEach((i) => {
            industry_graph_array.push(i.codename);
          });
          console.log("option", res);
          // this.sourceIndustryList2 = res.data
          // this.industryImportantList = res.data[0].option_list
          // if (this.env != "pro") {
          //   // this.industryImportantList = res.data[0].option_list
          //   let arr_tmp = {
          //     industry_type: "新能源汽车",
          //     option_list: [],
          //   };
          //   res.data[0].option_list.forEach((i) => {
          //     if (industry_graph_array.indexOf(i.code) != -1) {
          //       // 生成混淆dom元素
          //       i.name_fake = this.confuseSpider("linkName", i.name);
          //       arr_tmp.option_list.push(i);
          //     }
          //   });
          //   this.industryImportantList = arr_tmp.option_list;
          // } else {
          //   this.industryImportantList = res.data[0].option_list.filter(
          //     (x) =>
          //       x.is_online == 1 &&
          //       industry_graph_array.indexOf(x.code) != -1 &&
          //       x.clickable == true
          //   );
          // }
          this.industryImportantList = res.data[0].option_list.filter(
            (x) =>
              x.is_online == 1 &&
              industry_graph_array.indexOf(x.code) != -1 &&
              x.clickable == true
          );
          this.select_value = this.industryImportantList[0].name;
          this.getIndustryTree(this.industryImportantList[0]);
          console.log("industryImportantList", this.industryImportantList);
          res.data.forEach((r) => {
            // r.option_list.forEach(o => {
            //   if (industry_graph_permission.indexOf(o.code) != -1) {
            //     this.sourceIndustryList2.push(o);
            //   }
            // })
            this.sourceIndustryList2.push({
              name: r.industry_type,
            });
          });
          this.industryTreeLoading = false;
        })
        .catch((err) => {
          this.industryTreeLoading = false;
          console.error(err);
        });
    },

    // 获取行业构成分布
    getIndustryCount() {
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        permissions: cookie.getCookie("permissions"),
      };
      this.apiDataBarChart.legend = [];
      this.apiDataBarChart.data = [];
      this.progressList = [];
      api.getIndustryCount(params).then((res) => {
        console.log("行业统计", res);
        // this.apiDataPieChart = res.data;
        res.data.forEach((r) => {
          this.progressList.push({
            name: r.name,
            count: r.value,
          });
          this.progressTotal += r.value;
        });
        console.log("this.progressList", this.progressList);
        for (let i = res.data.length - 1; i >= 0; i--) {
          let name = "";
          if (res.data[i].name.length > 4) {
            name = res.data[i].name.substring(0, 4) + "...";
          } else {
            name = res.data[i].name;
          }
          this.apiDataBarChart.legend.push(name);
          this.apiDataBarChart.data.push(res.data[i].value);
        }
      });
    },
    // 选择产业舆情的产业
    chooseSourceIndustry(i) {
      // console.log(i);
      // this.sourceIndustryValue = i.name;
      // EventBus.$emit("choose_source_industry", i);
      console.log("path", this.$route.path);
      if (this.$route.path == "/industry_risk") {
        EventBus.$emit("choose_source_industry", i);
      }
    },
    // 返回
    back() {
      this.$router.push("/industry_graph");
      this.company_id_selected = 0;
      // this.getCompanyByBoard();
      // this.getIndustryCount();
    },
    // 防抖
    // debounce(fn, delay) {
    //   let timer = null; //借助闭包
    //   return function() {
    //     if (timer) {
    //       clearTimeout(timer);
    //     }
    //     timer = setTimeout(fn, delay); // 简化写法
    //   };
    // },
    // 过滤数据
    filterData(data, type) {
      let arrCon = [];
      let arrTemp = Object.keys(areaMap);
      let valueTemp = Object.values(areaMap);
      for (let i = 0; i < arrTemp.length; i++) {
        arrCon.push({
          nameEN: arrTemp[i],
          nameCN: valueTemp[i],
        });
      }
      // console.log("arrCon", arrCon);
      // console.log("data", this.data);
      if (type === 0) {
        data.forEach((item) => {
          for (let i = 0; i < arrCon.length; i++) {
            if (item.region === arrCon[i].nameEN) {
              item.region = arrCon[i].nameCN;
            }
          }
        });
      } else {
        data.forEach((item) => {
          for (let i = 0; i < arrCon.length; i++) {
            if (item.name === arrCon[i].nameEN) {
              item.name = arrCon[i].nameCN;
            }
          }
        });
      }

      // console.log("dataAfter", data);
      return 0;
    },
    getCompanyByProduct() {
      if (this.searchProduct !== "") {
        EventBus.$emit("productLoading", 1);
        if (this.globalOrChina == 2) {
          api
            .getCompanyByProduct(this.searchProduct)
            .then((res) => {
              let that = this;
              that.filterData(res.list_company, 0);

              let countComp = 0;
              let arr = [];
              res.list_company.forEach((item) => {
                if (
                  item["region"] === "中国香港" ||
                  item["region"] === "中国台湾"
                ) {
                  arr.push({
                    name: "中国",
                  });
                } else {
                  arr.push({
                    name: item["region"],
                  });
                }
              });
              arr = arr.reduce((obj, item) => {
                let find = obj.find((i) => i.name === item.name);
                let _d = {
                  ...item,
                  value: 1,
                };
                find ? (countComp++, find.value++) : obj.push(_d);
                return obj;
              }, []);
              that.compNumCN = countComp;
              console.log("res.list_company", res.list_company);
              console.log("arr", arr);
              let product_info = {
                list_company: res.list_company,
              };
              EventBus.$emit("searchProduct", product_info);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          EventBus.$emit("scatterLoading", 1);
          let params = {
            product: this.searchProduct,
          };
          api.getCompanyByProductSql(params).then((res) => {
            let product_info = {
              list_company: res.data,
            };
            EventBus.$emit("searchProduct", product_info);
          });
          api.getScatterByProduct(params).then((res) => {
            console.log("散点数据", res);
            EventBus.$emit("mapScatter", res.data);
          });
        }
      }
    },
    // 监听新上市公司点击
    listenChooseNewCompany() {
      EventBus.$on("choose_new_company", (data) => {
        this.company_id_selected = data;
        this.getCompanyBase(this.company_id_selected);
      });
    },
    // 监听公司id变化
    listenCompanyIdSelected() {
      EventBus.$on("company_id_selected", (data) => {
        console.log("=------------------------------company_id变化", data);
        this.company_id_selected = data;
        this.compList.forEach((company) => {
          if (company.company_id == data) {
            this.formLabelAlign.short_name = company.short_name;
            this.formLabelAlign.name = company.full_name;
            this.formLabelAlign.board_id = company.board_id;
            this.formLabelAlign.industry1 = company.industry1;
            this.formLabelAlign.code = company.code;
            this.formLabelAlign.market_time = company.market_time
              ? company.market_time.replace("T00:00:00Z", "")
              : "";
            this.formLabelAlign.founded_time = company.founded_time
              ? company.founded_time.replace("T00:00:00Z", "")
              : "";
            this.formLabelAlign.address = company.address;
            this.formLabelAlign.registered_capital = company.registered_capital;
            this.companyInfoData = this.formLabelAlign;
          }
        });
      });
    },
    // 监听产业招商全球/中国切换
    // listenGlobalOrChina() {
    //   EventBus.$on("globalOrChina", data => {
    //     this.globalOrChina = data
    //     this.getCompanyByProduct()
    //   })
    // },
    // 监听分页变化
    listenPageChange() {
      EventBus.$on("pageChange", (data) => {
        this.handlePageChangeCompany(data);
      });
    },
    // 监听行业图谱节点
    listenIndustryGraph() {
      EventBus.$on("industry_graph", (data) => {
        console.log("主页industry_graph", data);
        if (data.industry_level == 0) {
          this.companyParams.industry_level = "";
          this.companyParams.industry_node = "";
        } else {
          this.companyParams.industry_level = data.industry_level;
          this.companyParams.industry_node = data.industry_node;
        }

        // this.getCompanyByBoard();
      });
    },
    // 获取产业列表
    getIndustry() {
      this.loading = true;
      api.getIndustry(this.industryParams).then((res) => {
        console.log("产业列表", res);
        this.industryList = res.data;
        this.industryTotal = res.total;
        this.loading = false;
      });
    },
    // 获取产业列表(为了和行业图谱柱状图联动)
    getIndustryForBar() {
      let params = {
        page: 1,
        page_size: 100,
      };
      api.getIndustry(params).then((res) => {
        console.log("产业列表forBar", res);
        this.industryListForBar = res.data;
      });
    },
    debounce(fn, time) {
      let _arguments = arguments;
      let timeout = null;
      return function () {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          fn.call(this, _arguments);
        }, time);
      };
    },
    // 选择服务
    chooseServer(server) {
      // if (server.has_perm == true) {
      this.is_custom = false;
      if (server.value == "产业图谱") {
        this.customStatusChange();
        EventBus.$emit("clickToFirst"); // 点击服务进入产业链首页
        this.openeds = ["1"]; // 默认第一个侧边栏打开
      }
      this.getUserSubscribe();
      this.isIndustrialMap = !this.$route.path.includes("category_graph");
      this.sideCollapse = false;
      this.serverValue = server.value;
      console.log(this.serverValue);
      this.company_id_selected = 0;
      this.sourceIndustryValue = "";
      // EventBus.$emit("company_id", "");
      // if ((server.server = "cyl_qytp")) {
      //   this.getCompanyByBoard();
      // }
      // sessionStorage.setItem("company_id", "");
      cookie.setCookie("company_id", "");
      // if (server.value == "企业舆情") {
      EventBus.$emit("reset", 1);
      this.searchProduct = "";
      this.globalOrChina = 1;
      this.companyParams.page = 1;
      this.companyParams.industry1 = "";
      this.companyParams.industry_node = "";
      this.companyParams.industry_level = "";
      this.companyParams.product = "";
      this.companyParams.area = [];
      // this.getCompanyByBoard();
      // }
      // sessionStorage.setItem("serverValue", server.value);
      cookie.setCookie("serverValue", server.value);
      cookie.setCookie("serverIndex", server.index);
      this.$router.push(server.route);
      // } else {
      //   this.$message.warning("该账号暂无此产业链权限，请联系管理员开通VIP权限！");
      // }
    },
    // tab改变
    tabChangeHandler(i) {
      this.tabIndex = i.index;
      this.$router.push(i.route);
    },
    toSubscribe() {
      this.$router.push("/company_news");
    },
    // 右边切换专利列表和诉讼列表
    patentHandleClick(val) {
      console.log(val.name);
    },
    // 监听行业图谱柱状图点击
    listenIndustryClick() {
      EventBus.$on("industry_click", (data) => {
        this.industryListForBar.forEach((i) => {
          if (i.name.indexOf(data.replace("...", "")) != -1) {
            console.log("industry_id", i.id);
            this.chooseIndustry(i);
          }
        });
      });
    },
    //退出登录
    exit() {
      console.log("退出");
      sessionStorage.clear();
      cookie.delCookie("server");
      cookie.delCookie("vip_perm");
      cookie.delCookie("serverValue");
      cookie.delCookie("serverIndex");
      cookie.delCookie("account");
      cookie.delCookie("permissions");
      cookie.delCookie("company_id");
      cookie.delCookie("role");
      cookie.delCookie("token");
      cookie.delCookie("industry_graph");
      cookie.delCookie("industry_news");
      if (window.top === window.self) {
        console.log("top = self");
      } else {
        console.log("exit", document.referrer);
        console.log(window);
        console.log(window.parent);
        let params = {
          token: cookie.getCookie("dianlian_token"),
          auto: true,
        };
        let type = "dev";
        if (document.referrer == "https://uat-cloud.dianliantech.com/") {
          type = "dev";
        } else if (document.referrer == "https://cloud.dianliantech.com/") {
          type = "pro";
        }
        api.removeTokenDianLian(params, type).then((res) => { });
        window.parent.postMessage(
          {
            type: "loginOut",
          },
          document.referrer
        );
      }
      this.$router.push("/");
      location.reload();
    },
    handleCheckAllChange(val) {
      this.checkedBoards = val ? this.boards : [];
      this.isIndeterminate = false;
      console.log("全选", val);
    },
    handleCheckedBoardsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.boards.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.boards.length;
      console.log("板块选择", value);
    },
    handleChangeCascader(value) {
      console.log("=====", value);
      this.companyParams.page = 1;
      for (let i = 0; i < value.length; i++) {
        value[i] = provincesAndCityMap[value[i]];
      }
      console.log(value);
      // this.getCompanyByBoard(0);
    },
    // 点击公司防抖
    // clickCompany(company) {
    //   this.debounce(this.chooseCompany(company), 1000);
    // },
    // 选择产业
    chooseIndustry(industry) {
      EventBus.$emit("industryId", industry.id);
    },
    // 选择公司
    chooseCompany(company) {
      console.log(company);
      this.company_id_selected = company.company_id;
      this.getCompanyBase(this.company_id_selected);
      // this.formLabelAlign.short_name = company.short_name;
      // this.formLabelAlign.name = company.full_name;
      // this.formLabelAlign.board_id = company.board_id;
      // this.formLabelAlign.industry1 = company.industry1;
      // this.formLabelAlign.code = company.code;
      // this.formLabelAlign.market_time = company.market_time.replace(
      //   "T00:00:00Z",
      //   ""
      // );
      // this.formLabelAlign.founded_time = company.founded_time.replace(
      //   "T00:00:00Z",
      //   ""
      // );
      // this.formLabelAlign.address = company.address;
      // this.formLabelAlign.registered_capital = company.registered_capital;

      // this.companyInfoData = this.formLabelAlign;
      EventBus.$emit("short_name", company.short_name);
      EventBus.$emit("company_id", company.company_id);
      // sessionStorage.setItem("company_id", company.company_id);
      cookie.setCookie("company_id", company.company_id);
      console.log("Home emit", company.company_id);
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    handleCollapse(val) {
      console.log("handleCollapse", val);
      console.log("handleCollapseTrue", val.indexOf("2"));
      if (val.indexOf("2") != -1) {
        if (this.lineChart) {
          this.$nextTick(() => {
            echarts.init(this.$refs.lineChart).resize();
          });
        }
        if (this.lineChart2) {
          this.$nextTick(() => {
            echarts.init(this.$refs.lineChart2).resize();
          });
        }
      }
      if (val.indexOf("3") != -1) {
        this.$nextTick(() => {
          echarts.init(this.$refs.graphChart).resize();
        });
      }
    },
    // 跳转至指定网页
    toWeb(url) {
      window.open(url, "_blank");
    },
    handlePageChangeCompany(val) {
      this.companyParams.page = val;
      // this.getCompanyByBoard(this.sourceActiveName);
    },
    handlePageChangeIndustry(val) {
      this.industryParams.page = val;
      this.getIndustry();
    },
    // 获取当前日期
    getToday() {
      let myDate = new Date();
      let date = myDate.toLocaleDateString().split("/");
      // console.log("date", date)
      return `${date[0]}-${this.prefixZero(date[1], 2)}-${this.prefixZero(
        date[2],
        2
      )}`;
    },
    // 前补0
    prefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    // 获取企业类型统计
    getTypeCount() {
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        permissions: cookie.getCookie("permissions"),
      };
      api.getTypeCount(params).then((res) => {
        console.log("类型统计", res);
        this.apiDataPieChart2 = res.data;
      });
    },
    // 监听产业选择
    listenIndexIndustry() {
      EventBus.$on("index_industry", (data) => {
        console.log("监听产业选择==========", data);
        // this.index_industry = data.name;
        // this.$store.commit("changeIndexIndustry", data.name)
        // this.select_value = data.name;
        // this.getIndustryTree(data.name);
      });
    },
    // 监听产业生态位点击
    listenIndustryPosition() {
      EventBus.$on("industry_position", (data) => {
        console.log("industry_position", data);
        // this.getIndustryTree(data.name);
      });
    },
    check() {
      function doCheck(a) {
        if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
          (function () { }["constructor"]("debugger")());
        } else {
          (function () { }["constructor"]("debugger")());
        }
        doCheck(++a);
      }
      try {
        doCheck(0);
      } catch (err) { }
    },
  },
  mounted() {
    this.is_normal = cookie.getCookie("is_normal");
    this.is_customIndustry =
      cookie.getCookie("module").indexOf("custom_industry") != -1
        ? true
        : false;
    this.isIndustrialMap = this.$route.path.includes("category_graph");
    // setInterval(() => {
    //   this.check()
    // }, 1000)
    this.newsShowLine = utils.randomNum(1, 3);
    let node = {
      name: "锂电池正极材料",
      domain_id: 44,
      node_link_list: ["锂电池正极材料"],
    };
    EventBus.$emit("industry_drop", node);
    sessionStorage.setItem("newsShowLine", this.newsShowLine);
    let news_style_text = `#newsTitle:nth-of-type(${this.newsShowLine}) {display: none !important; }`;
    let link_style_text = `#linkName:nth-of-type(${this.newsShowLine}) {display: none !important; }`;
    let tree_style_text = `#treeName:nth-of-type(${this.newsShowLine}) {display: none !important; }`;
    utils.addCSS(news_style_text);
    utils.addCSS(link_style_text);
    utils.addCSS(tree_style_text);
    news_style_text = `#newsTitle:nth-of-type(${this.newsShowLine}) {display: inline !important; }`;
    link_style_text = `#linkName:nth-of-type(${this.newsShowLine}) {display: inline !important; }`;
    tree_style_text = `#treeName:nth-of-type(${this.newsShowLine}) {display: inline !important; }`;
    utils.addCSS(news_style_text);
    utils.addCSS(link_style_text);
    utils.addCSS(tree_style_text);
    console.log("当前环境", process.env.VUE_APP_CURRENTMODE);
    console.log("版本", process.env.APP_VERSION);
    this.env = process.env.VUE_APP_CURRENTMODE;
    // if (this.env == 'pro') {
    //   setInterval(() => {
    //     this.check()
    //   }, 1000)
    // }
    // this.getIndustryTree("新能源汽车")
    // let index_industry = {
    //   code: "cytp_xnyqc",
    //   domain_id: 1,
    //   downstream_id: 1,
    //   image_url: "https://suwen-data.obs.cn-south-1.myhuaweicloud.com/frontend_industryradar/xnyqc.png",
    //   industry_en: "ele_car",
    //   industry_type: "新能源汽车",
    //   is_online: 1,
    //   name: "新能源汽车",
    // };
    let index_industry = {
      industry_type: "锂电池正极材料",
      domain_id: 44,
      downstream_id: null,
      industry_en: "cytp_ldczjcl",
      name: "锂电池正极材料",
      image_url:
        "https://suwen-data.obs.cn-south-1.myhuaweicloud.com/frontend_industryradar/cytp_ldczjcl.png",
      code: "cytp_ldczjcl",
      is_online: 1,
    };
    sessionStorage.setItem("index_industry", JSON.stringify(index_industry));
    console.log(
      `%c当前版本号：%c${this.version}`,
      "color: blue;font-weight:bold",
      "color: orange;font-weight:bold"
    );
    this.serverValue = cookie.getCookie("serverValue")
      ? cookie.getCookie("serverValue")
      : "企业舆情";
    console.log("mounted：", this.serverValue);
    this.nowActive = cookie.getCookie("serverIndex")
      ? cookie.getCookie("serverIndex")
      : "1";
    // this.account = sessionStorage.getItem("account");
    this.account = cookie.getCookie("account");
    this.getUserSubscribe();
    this.companyParams.username = this.searchNodeParams.username = this.account;
    // this.companyParams.permissions = sessionStorage.getItem("permissions");
    this.companyParams.permissions = cookie.getCookie("permissions");
    console.log("用户名", cookie.getCookie("account"));
    // this.getUserInfo();
    this.getNewsIndustry();
    this.getIndustryGraphOptionRadar();
    this.getCustomNode();
    // this.getIndustryTree("新能源汽车");
    this.listenIndexIndustry();
    this.listenIndustryPosition();
    this.listenIndustryClick();
    this.listenPageChange();
    this.listenIndustryGraph();
    this.listenCompanyIdSelected();
    this.listenChooseNewCompany();
    this.listenIndustryComposition();
    this.listenAddUserSubscribe();
    this.listenCancelSubscribe();
    this.listenChangeNodeName();
    this.getIndustry();
    this.getIndustryForBar();
    // console.log("server", sessionStorage.getItem("server").split(","))
    // this.serverOptions.push({
    //   value: "主页",
    //   label: "主页",
    //   route: "/firstPage",
    //   server: "kjld_zy",
    //   icon: "el-icon-s-home",
    //   index: "1",
    // });
    this.serverOptionsTotal.forEach((s) => {
      console.log("server", JSON.parse(cookie.getCookie("server")));
      JSON.parse(cookie.getCookie("server")).forEach((i) => {
        if (
          s.server == i.codename &&
          i.has_perm == true &&
          s.server != "kjld_qytp"
        ) {
          // console.log("s", s);
          // s.has_perm = i.has_perm;
          this.serverOptions.push(s);
        }
      });
      console.log('@@@', this.serverOptions);
    });
    // this.serverOptions = this.serverOptionsTotal;
    console.log("company_id_selected", this.company_id_selected);
    // this.serverValue = sessionStorage.getItem("serverValue")
    //   ? sessionStorage.getItem("serverValue")
    //   : "企业舆情";
    let timer = null;
    window.onresize = () => {
      console.log("resize");
      if (!timer) {
        timer = setTimeout(() => {
          console.log("窗口变化");
          // if (this.graphChart) {
          //   echarts.init(this.$refs.graphChart).resize();
          // }
          // this.$refs.financialEcharts.echartResize()
          // this.$refs.productEcharts.echartResize()
          timer = null;
        }, 500);
      }
    };
    // this.getPdfBasic();
    let clickDuration = 0;
    document.onmousedown = function (event) {
      this.isClick = true;
      let d = new Date();
      clickDuration = d.getTime();
      // console.log('down', d.getTime());
    };
    let that = this;
    document.onmouseup = function (event) {
      let d = new Date();
      // console.log('up', d.getTime());
      clickDuration = d.getTime() - clickDuration;
      // console.log('duration', this.clickDuration);
      if (clickDuration > 300) {
        that.$nextTick(() => {
          that.isClick = false;
          setTimeout(() => {
            that.isClick = true;
          }, 200);
        });
      }
    };
  },
  beforeDestroy() {
    console.log("Home破坏前");
    EventBus.$off("industry_click");
    EventBus.$off("pageChange");
    EventBus.$off("industry_graph");
    // EventBus.$off("globalOrChina")
    EventBus.$off("company_id_selected");
    EventBus.$off("industry_count");
    EventBus.$off("index_industry");
    EventBus.$off("industry_position");
    EventBus.$off("addUserSubscribe");
    EventBus.$off("cancelSubscribe");
    EventBus.$off("changeNodeName");
  },
};
</script>
<style lang="scss">
.home {
  #star {
    .el-dialog__body {
      padding-top: 0px;
    }
  }

  // 修改自定义icon大小
  .iconfont {
    font-size: 22px;
  }

  .el-menu {
    border: none;
  }

  .el-menu-item-group__title {
    display: none;
  }

  .el-submenu__title {
    background-color: #e3effb;
    padding: 0;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .slide_icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  // 修改el-icon大小
  .el-menu-item [class^="el-icon-"] {
    font-size: 22px;
  }

  background-color: #f7f7f7;
  // background-color: white;
  min-height: 100vh;

  // padding-bottom: 20px;
  // overflow-y: sc;
  .is-focusable {
    color: #333;
  }

  .is-current {
    color: #01a4d9;
  }

  .el-pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .searchInput {
    .el-input__inner {
      padding-right: 30px;
    }
  }

  .link_menu {
    &_item {
      background-color: #e3effb;
    }
  }

  .menu_my_sub {
    .el-menu-item {
      color: #4095e5;
    }
  }

  .el-menu--collapse {
    box-shadow: 4px 0 6px -5px silver;
    flex-shrink: 0;
  }

  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .el-collapse-item__header {
    padding-left: 15px;
  }

  .el-collapse-item__content {
    padding-bottom: 0px;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .checkBox {
    position: relative;
    overflow: hidden;
    // padding-top: 10px;
    // padding-left: 5px;
    min-width: 207px;
    height: calc(100vh - 90px);
    background-color: white;
    // margin-bottom: 10px;
    box-shadow: 0 2px 12px 0 rgba(22, 18, 18, 0.11);
    border-radius: 6px;

    // border-bottom: 1px solid rgb(231, 230, 230);
    .el-input--medium {
      font-size: 16px;
    }

    .el-tree-node__label {
      font-size: 16px;
    }

    .el-checkbox {
      margin-bottom: 7px;
    }
  }

  .el-form-item {
    margin-bottom: 12px;
    margin-left: 10px;
  }

  .bombBox {
    color: #333333;
    z-index: 3000;

    .event {
      width: 20%;
    }

    .heat {
      width: 10%;
      text-align: center;
    }

    .news {
      width: 70%;
    }

    &-layer {
      position: fixed;
      z-index: 999;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(34, 36, 37, 0.7);
    }

    &-content_v2 {
      width: 95%;
      margin-left: 2.5%;

      &-subtitle {
        font-size: 14px;
        margin-left: 2px;
        color: #333333;
      }
    }

    &-content {
      position: fixed;
      z-index: 1000;
      left: 20vw;
      top: 15vh;
      width: 60vw;
      height: 70vh;
      overflow-y: scroll;
      // border: 1px solid rgb(17, 127, 163);
      box-shadow: #34465a;
      border-radius: 6px;
      // background-color: rgba(4, 21, 39, 0.95);
      background-color: white;
      padding: 8px;

      &-title {
        // padding: 0 5px 0 5px;
        color: white;
        margin: 0 auto;
        font-size: 1vw;
        width: 5vw;
        height: 1.5vw;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: skew(-20deg);
        background-color: #01a4d9;
      }

      &-subtitle {
        display: inline-block;
        font-size: 18px;
        // padding: 2px 3px 5px 3px;
        // color: #2fbdec;
        color: #333333;
        // font-weight: bold;
        // border-bottom: 1px solid white;
        margin-left: 15px;
        margin-top: 25px;

        &:first-of-type {
          margin-top: 10px;
        }
      }
    }

    &-basic {
      &-content {
        margin-left: 20px;
        margin-top: 10px;
      }
    }
  }

  .selectAndChart {
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
  }

  .tagBlue {
    margin-left: 10px;
    font-size: 12px;
    padding: 1px 3px 1px 3px;
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409eff;
    border-radius: 4px;
    border: 1px solid #d9ecff;
  }

  .content {
    display: flex;
    // justify-content: space-between;
  }

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    height: 60px;
    z-index: 100;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-leftPart {
      display: flex;
      align-items: center;
      color: rgb(51, 122, 183);
      font-weight: bold;
    }

    &-rightPart {
      display: flex;
      align-items: center;
    }

    .countBtnCrew {
      margin-left: 30px;
    }

    p {
      margin-left: 20px;
      font-size: 24px;
      cursor: pointer;
    }

    .search {
      width: 170px;
      height: 34px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      display: flex;
      align-items: center;
      margin-right: 0.5vw;

      &-btn {
        // margin-right: ;
      }
    }

    .search:hover {
      border: 1px solid #409eff;
    }

    .icon {
      height: 26px;
      width: 26px;
      margin-left: 5px;
    }

    .input {
      height: 24px;
      width: 70%;
      border: none;
      outline: none;
    }

    input::-webkit-input-placeholder {
      color: silver;
      font-size: 14px;
      padding: 5px;
    }

    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: silver;
      font-size: 14px;
      padding: 5px;
    }

    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: silver;
      font-size: 14px;
      padding: 5px;
    }
  }

  .title {
    font-weight: bold;
    padding-left: 18px;
    border-bottom: 1px solid #f5f4f9;
    height: 45px;
    display: flex;
    align-items: center;
  }

  .leftPart {
    width: 20vw;
    transition-duration: 0.5s;
    height: calc(100vh - 70px);
    // background-color: white;
    background-color: #f7f7f7;
    display: flex;
    // border-right: 1px #e6e6e6 solid;
    overflow: hidden;
    flex-shrink: 0;
  }

  .selectContent {
    // overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 210px);
  }

  .sourceList {
    font-size: 17px;
    margin-top: 5px;
    // margin-left: 10px;
    background: #fff;
    width: 100%;
    min-height: 280px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    border-radius: 7px;
  }

  .eventBox {
    font-size: 17px;
    margin-top: 20px;
    margin-left: 10px;
    background: #fff;
    width: 98%;
    min-height: 80vh;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    border-radius: 7px;
  }

  .sourceItemCrew,
  .newsItemCrew {
    // margin-top: 10px;
    // max-height: 70vh;
    // overflow-y: scroll;
  }

  .sourceItemCrew {
    padding-top: 0px;
  }

  .newsItemCrew {
    // max-height: 70vw;
    margin-top: 10px;
  }

  .sourceItem {
    cursor: pointer;
    font-size: 16px;
    min-height: 20px;
    padding-left: 15px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f5f4f9;

    // &-name {
    //   display: flex;
    //   justify-content: space-between;
    // }
    &-tag,
    &-tag2,
    &-tagBlue {
      margin-left: 10px;
      font-size: 12px;
      padding: 1px 3px 1px 3px;
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
      border-radius: 4px;
      border: 1px solid #d9ecff;
    }

    &-tag2 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }

    &-tagBlue {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409eff;
    }

    &-time,
    &-source,
    &-webSource {
      display: inline-block;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-left: 10px;
    }

    &-source {
      margin-left: 10px;
    }

    &-webSource {
      margin-left: 20px;
    }

    &-website {
      color: rgb(49, 135, 216);
      font-size: 14px;
      margin-top: 5px;
      word-break: break-all;
      margin-right: 10px;
    }
  }

  .centerPart {
    // width: 79vw;
    // width: calc(100vw - 130px);
    width: 100vw;
    height: calc(100vh - 87px);
    margin-top: 10px;
    margin-left: 20px;
    // padding-right: 15px;
    margin-right: 10px;
    border-radius: 6px;
    // overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    box-shadow: 0 2px 12px 0 rgba(22, 18, 18, 0.11);

    // overflow: hidden;
    // background: gray;
    // padding-top: 52px;
    // margin-left: 0.1vw;
    &-header {
      z-index: 200;
      color: #333333;
      background-color: #337ab7;
      color: white;
      // position: fixed;
      // top: 61px;
      // width: calc(100vw - 64px);
      width: 100% !important;
      padding-left: 20px;
      height: 50px;
      // background-color: white;
      // box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-content {
      // padding-left: 5px;
    }
  }

  // .newsList {
  //   font-size: 17px;
  //   margin-top: 20px;
  //   // margin-left: 10px;
  //   background: #fff;
  //   width: 100%;
  //   min-height: 280px;
  //   -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  //   box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  //   border-radius: 7px;
  // }
  .rightPart {
    width: 25vw;
  }

  .flexBox {
    display: flex;
    align-items: center;

    &-title {
      white-space: nowrap;
    }
  }

  .contentFooter {
    height: 50px;
    display: flex;
    // align-items: center;
    justify-content: center;
  }

  .btn {
    &:last-of-type {
      margin-right: 15px;
    }
  }

  .event {
    &-tag {
      display: flex;
      margin-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #f5f4f9;

      &-item {
        cursor: pointer;
        margin-right: 8px;
        font-size: 14px;
        padding: 1px 3px 1px 3px;
        border-color: #e1f3d8;
        border-radius: 4px;
        border: 1px solid #d9ecff;
        background-color: #ecf5ff;
        border-color: #d9ecff;
        color: #409eff;
      }
    }

    &-header {
      border-bottom: 1px solid #f5f4f9;
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: bold;
      padding-left: 20px;
    }

    &-content {
      padding-bottom: 10px;
    }

    &-type {
      width: 25%;
    }

    &-item {
      // cursor: pointer;
      // display: flex;
      margin-right: 10px;
      margin-top: 25px;
      min-height: 40px;
      padding-bottom: 5px;
      font-size: 13px;
      align-items: center;
      // border-bottom: 1px solid #f5f4f9;
      padding-left: 15px;

      &-type {
        width: 25%;
        font-size: 14px;
        color: #3d78ba;
        font-weight: bold;
      }

      &-event {
        margin-top: 5px;
        // width: 30%;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }

      &-info {
        // width: 30%;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
  }

  // .pie,
  // .bar {
  //   min-height: 500px;
  //   margin-bottom: 20px;
  // }
  .newsDetail {
    .btnBox {
      display: flex;
      margin-top: 30px;
      width: 100%;
      justify-content: center;
    }

    &-content {
      width: 100%;
      // margin-left: 0%;
      // margin-top: 10px;
      margin-bottom: 10px;
      padding-bottom: 20px;
      min-height: 40vh;
      background: #fff;
      -webkit-box-shadow: 0 1px 10px 0 hsla(0, 0%, 63.9%, 0.5);
      box-shadow: 0 1px 10px 0 hsla(0, 0%, 63.9%, 0.5);
      border-radius: 7px;

      &-text {
        margin-left: 60px;
        margin-right: 10px;
        margin-top: 40px;
        max-height: 50vh;
        overflow-y: scroll;
        padding-right: 50px;

        .basicTitle {
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .basicInfo {
          margin-top: 5px;
          margin-left: 10px;
        }
      }

      &-header {
        background: #34465a;
        border-radius: 7px 7px 0 0;
        width: 100%;
        // padding: 0 60px;
        min-height: 70px;
        color: white;

        .newsDetailTitle {
          margin-left: 60px;
          padding-top: 10px;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .des {
          margin-left: 60px;
          margin-top: 5px;
        }

        span {
          display: inline-block;
          margin-top: 8px;
          margin-right: 12px;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }

  .newsTitle,
  .listedTitle {
    display: flex;
    justify-content: space-between;
    // padding-right: 10px;
    width: 95%;

    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow:ellipsis;
    &-polarity,
    &-polarityBad {
      opacity: 0.9;
      height: 20px;
      min-width: 32px;
      margin-left: 10px;
      // margin-right: 10px;
      background-color: green;
      color: white;
      font-size: 12px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-polarityBad {
      background-color: rgb(201, 37, 37);
    }
  }

  .listedTitle {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // table {
  //   margin-top: 5px;
  //   margin-left: 10px;
  // }
  // td {
  //   border-width: 1px 1pt 1pt;
  //   border-style: outset;
  //   padding: 2pt 5pt 2pt 5pt;
  // }
  .holderTable {
    tr:first-of-type {
      background-color: rgb(209, 205, 205);
    }
  }

  .newsDetail-content::v-deep img {
    width: 50%;
  }

  .el-tabs__item {
    padding: 0 10px;
  }

  .tabs {
    width: 90%;
    margin-left: 5%;
  }

  .select {
    &-industry {
      margin-right: 10px;
      margin-left: 10px;

      // margin-bottom: 10px;
      .el-tag--mini {
        height: 18px;
      }
    }
  }

  .select-industry span:focus-within {
    border: none;
    outline: none;
  }

  .empty {
    width: 100%;
    margin-top: 4vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &-img {
      width: 25%;
    }

    p {
      margin-top: 1vw;
      font-size: 1.5vw;
      color: #b1adad;
    }
  }

  .cityChoose {
    // margin-left: 15px;
    margin-bottom: 10px;
  }

  .companyInfo {
    &-item {
      width: 70%;
    }

    &-btn {
      // height:100%;
      // margin-top:1%;
      margin-left: 10px;
      margin-bottom: 12px;
    }

    &-title {
      margin-right: 20px;
    }
  }

  .companyHead {
    // display: flex;
    margin-bottom: 10px;

    &-name {
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #337ab7;
    }
  }

  .companySearch {
    width: 90%;
    margin-left: 15px;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .searchBtn {
    margin-left: 10px;
    // margin-bottom: 10px;
    margin-right: 10px;
  }

  .filter {
    margin-bottom: 10px;
  }

  .graphContainer {
    width: 96%;
    margin-left: 2%;
    height: 450px;
  }

  .graphChart {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }

  .searchAndExit {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .exit {
    display: flex;
    align-items: center;
    margin-right: 50px;

    p {
      font-size: 18px;
      color: #333333;
    }

    &-btn {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .user {
    border: 2px solid #65676b;
    cursor: pointer;
    margin-left: 10px;
    // margin-right: 10px;
    border-radius: 50%;
    height: 29px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;

    [class*=" el-icon-"],
    [class^="el-icon-"] {
      font-size: 20px;
    }
  }

  .myServer {
    &-title {
      font-size: 13px;
      color: #333333;
      margin-bottom: 10px;
    }

    &-content {
      padding-left: 20px;
    }

    &-option,
    &-optionSelected {
      cursor: pointer;
      color: #333333;
      margin-bottom: 5px;
    }

    &-optionSelected {
      color: #01a4d9;
      font-weight: bold;
    }
  }

  .datePicker {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 30%;
  }

  .tagBox {
    display: flex;
    width: 95%;
    margin-left: 2.5%;
    flex-wrap: wrap;

    div {
      margin-bottom: 5px;
    }
  }

  .tab {
    display: flex;
    color: #0784ad;

    // margin-left: 4%;
    &-industry,
    &-company {
      padding: 0 10px 0 10px;
      height: 28px;
      border: 1px solid #01a4d9;
      font-size: 18px;
      // font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    &-industry {
      // border-top-left-radius: 4px;
      // border-bottom-left-radius: 4px;
      transform: skew(-20deg);
    }

    &-company {
      // border-top-right-radius: 4px;
      // border-bottom-right-radius: 4px;
      transform: skew(-20deg);
      border-right: none;

      &:last-child {
        border-right: 1px solid #01a4d9;
      }
    }

    &-selected,
    &-selectedSmall {
      background-color: #01a4d9;
      color: white;
    }

    &-selectedSmall {
      height: 22px;
      font-size: 16px;
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  .pageTotal {
    // text-align: right;
    margin-left: 15px;
    font-size: 13px;
    color: #9195a3;
    margin-bottom: 15px;
  }

  .sourceIndustry {
    font-size: 16px;
    padding-left: 20px;
    padding-bottom: 10px;

    &-item {
      margin-bottom: 5px;
      cursor: pointer;
    }
  }

  .blue_bold {
    font-weight: bold;
    color: #01a4d9;
    margin-bottom: 5px;
  }

  .industry_progress {
    // padding-left: 0 15px 20px 15px;
    font-size: 15px;
    padding-bottom: 20px;
  }

  .progressBox {
    margin-left: 15px;
  }

  // .userInfo {
  //   &-box {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;
  //     width: 99%;
  //     margin-top: 7vh;
  //     // min-height: 60vh;
  //   }
  //   .el-dialog {
  //     height: 90vh;
  //     // position: fixed;
  //   }
  // }
  .version {
    color: gray;
    font-size: 10px;
    margin-left: 10px;
  }

  .personalInfo {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 2000;

    &-layer {
      position: absolute;
      width: 100vw;
      height: 100vh;
      // z-index: 2000;
      z-index: 2000;
      background-color: rgba(0, 0, 0, 0.7);
    }

    &-content {
      position: relative;
      width: 70vw;
      margin-left: 15vw;
      height: 80vh;
      background-color: white;
      border-radius: 6px;
      margin-top: 10vh;
      overflow: hidden;
      z-index: 3000;
    }

    &-header {
      width: 100%;
      height: 5vh;
      background-color: #409eff;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        margin-left: 15px;
      }

      &-close {
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }

  .info-ani-enter-active {
    transition: opacity 0.5s;
  }

  .info-ani-enter {
    opacity: 0;
  }

  .info-ani-leave-active {
    transition: opacity 0.5s;
  }

  .info-ani-leave-to {
    opacity: 0;
  }

  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;

    // min-height: 0vh;
    // border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;

      p {
        font-size: 14px;
        margin: 5px 0;

        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }

      h1 {
        margin: 5px 0;
        font-size: 18px;

        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }

    .detail {
      width: 100%;

      // max-width: 1200px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }

      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
    }
  }

  .industry_node_search_list {
    min-height: 300px;
  }

  .btnShowMore {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
  }

  .el-input-group__append {
    background-color: #337ab7;
    color: white;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    padding: 0 20px;
    width: 1px;
    white-space: nowrap;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .input-with-select {
    margin: 10px 5px 10px 5px;
  }

  .el-dropdown-menu__item {
    color: #337ab7 !important;
  }

  .footer {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    background-color: #1c1f37;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(235, 231, 231);
    font-size: 13px;

    &-text {
      margin-top: 15px;
    }
  }
}

.logo {
  width: 203.41px;
  height: 50px;
  border: none;
  margin-left: 10px;
}

.menu_my_sub {
  .el-menu-item-group {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
