<template>
  <div class="industryPie">
    <div class="pie" ref="pie"></div>
  </div>
</template>

<script>
export default {
  name: 'IndustryPie',
  props: {
    apiDataPieChart: Array
  },
  data() {
    return {
      myPie: ""
    }
  },
  watch: {
    apiDataPieChart: {
      handler(newVal, oldVal) {
        this.setOriginPie()
        this.setPie()
      }
    }
  },
  methods: {
    // 饼图初始化
    setOriginPie() {
      this.myPie = echarts.init(this.$refs.pie);
    },
    // 设置饼图
    setPie() {
      this.myPie.setOption({
        // title: {
        //   text: "各行业分布情况",
        //   left: "center",
        // },
        width: "95%",
        height: "100%",
        tooltip: {},
        title: {
          text: "行业统计",
          left: "center",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          left: "left",
          top: "center",
          data: this.apiDataPieChart,
        },
        series: [
          {
            // name: "该行业公司数量",
            type: "pie",
            // roseType: "radius",
            radius: [0, '60%'],
            data: this.apiDataPieChart,
            center: ["50%", "50%"],
            left: "center",
            top: "center",
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              show: false,
            },
          },
        ],
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.industryPie {
  width: 49.5%;
  margin-left: 5px;
  margin-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 6px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  .pie {
    min-height: 250px;
    // width: 50%;
  }
}
</style>
