<template>
  <div class="companyInfo">
    <!-- <div class="title">
      <p id="zjxw">公司信息</p>
    </div> -->
    <div class="companyHead">
      <span class="companyHead-name">{{ companyInfoData.short_name }}</span>
      <span class="tagBlue" slot="reference" v-if="companyInfoData.board_id < 7">{{ board_id_map[companyInfoData.board_id-1] }} {{ companyInfoData.code }}</span>
      <span class="tagBlue" slot="reference" v-if="companyInfoData.industry1">{{
        companyInfoData.industry1
      }}</span>
      <span class="companyHead-time" v-if="companyInfoData.board_id<7">上市时间：{{ companyInfoData.market_time }}</span>
    </div>
    <el-form
      class="info"
      label-position="right"
      label-width="80px"
      :model="companyInfoData"
    >
      <!-- <el-form-item label="公司全称" size="medium">
        <el-input
          v-model="companyInfoData.name"
          readonly
          style="width: 60%"
        ></el-input>
      </el-form-item> -->
      <p class="info-text">公司全称：{{ companyInfoData.name }}</p>
      <!-- <el-form-item label="地址" size="medium">
          <el-input v-model="companyInfoData.address" readonly  style="width: 60%"></el-input>
        </el-form-item> -->
        <p class="info-text">地址：{{ companyInfoData.address }}</p>
      <div class="flexBox" v-if="companyInfoData.board_id < 7">
        <!-- <el-form-item label="成立日期" size="medium">
          <el-input v-model="companyInfoData.founded_time" readonly></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="注册资本" size="medium">
          <el-input v-model="companyInfoData.registered_capital" readonly></el-input>
        </el-form-item> -->
        <p class="info-text">成立日期：{{ companyInfoData.founded_time }}</p>
        <p class="info-text">注册资本：{{ companyInfoData.registered_capital }}</p>
        
      </div>
      <!-- <div class="flexBox" v-if="companyInfoData.board_id < 7">
        <el-form-item label="上市时间" size="medium">
          <el-input v-model="companyInfoData.market_time" readonly></el-input>
        </el-form-item>
        <el-form-item label="股票代码" size="medium">
          <el-input v-model="companyInfoData.code" readonly></el-input>
        </el-form-item>
      </div> -->
    </el-form>
  </div>
</template>

<script>
export default {
  name: "CompanyInfo",
  props: {
    companyInfoData: Object,
  },
  data() {
    return {
      board_id_map: [
        "深圳主板",
        "上海主板",
        "深圳中小板",
        "深圳创业板",
        "上海科创板",
        "新三板",
      ]
      // companyInfoData: {
      //   short_name: "深圳市腾讯计算机有限公司",
      //   name: "xxx",
      //   industry1: "制造业",
      //   code: "22933",
      //   market_time: "2020-04-02",
      //   capital: "4560万",
      //   address: "广东省深圳市南山区腾讯大厦",
      // },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.companyInfo {
  width: 99.8%;
  margin-left: 5px;
  margin-top: 10px;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  // margin-bottom: 5px;
  // padding-top: 10px;
  .companyHead {
    // display: flex;
    padding-top: 10px;
    margin-bottom: 10px;
    &-name {
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #337ab7;
    }
    &-time {
      font-size: 12px;
      color: #337ab7;
      margin-left: 10px;
    }
  }
  .tagBlue,
  .tagRed,
  .tagGreen {
    margin-left: 10px;
    font-size: 12px;
    padding: 1px 3px 1px 3px;
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409eff;
    border-radius: 4px;
    border: 1px solid #d9ecff;
  }
  .tagRed {
    color: #f56c6c;
    background-color: #fef0f0;
    border-color: #fde2e2;
  }
  .tagGreen {
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    color: #67c23a;
  }
  .info {
    border-bottom: 1px solid #f5f4f9;
    width: 90%;
    padding-right: 10%;
    margin-top: 10px;
    &-text {
      font-size: 14px;
      margin-left: 20px;
      margin-bottom: 10px;
      color: #333333;
    }
  }
  .title {
    font-size: 17px;
    height: 40px;
    margin-bottom: 5px;
  }
}
</style>
