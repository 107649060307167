<template>
  <el-dialog
    class="favorites"
    id="star"
    title="收藏内容"
    :visible.sync="showStar"
    destroy-on-close
  >
    <div class="starContent">
      <el-tabs
        v-model="starActive"
        @tab-click="handleStarActive"
        class="starTab"
      >
        <el-tab-pane label="新闻" name="news"></el-tab-pane>
        <el-tab-pane label="招聘" name="post"></el-tab-pane>
        <el-tab-pane label="专利" name="patent"></el-tab-pane>
      </el-tabs>
      <div class="starContent-content" v-loading="starLoading">
      <NewsList
        v-if="starActive == 'news'"
        :apiDataNewsList="starNewsList"
        :hasKeyword="false"
        :page="starParams.page"
        :page_size="starParams.page_size"
        :total="star_total"
        @changePage="handlePageChange"
        @cancelStar="handleCancelStar"
        :isFavorites="true"
      ></NewsList>
      <PostTable
        v-if="starActive == 'post'"
        :apiData="starPostList"
        :env="env"
        :post_type="'按公司'"
        :page="starParams.page"
        :page_size="starParams.page_size"
        :post_total="star_total"
        @changePostPage="handlePageChange"
        @cancelStar="handleCancelStar"
        :isFavorites="true"
      ></PostTable>
      <PatentCardList
        v-if="starActive == 'patent'"
        :apiData="starPatentList"
        :showModal="true"
        :total="star_total"
        :page="starParams.page"
        :page_size="starParams.page_size"
        @changePage="handlePageChange"
        @cancelStar="handleCancelStar"
        :isFavorites="true"
      ></PatentCardList>
    </div>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api/api";
import NewsList from "@/components/NewsList.vue";
import PostTable from "@/components/PostTable.vue";
import PatentCardList from "@/components/PatentCardList.vue";

export default {
  name: "Favorites",
  // props: {
  //   apiData: String
  // }
  components: {
    NewsList,
    PostTable,
    PatentCardList,
  },
  data() {
    return {
      env: "",
      showStar: false,
      starActive: "news",
      starLoading: false,
      starNewsList: [],
      starPostList: [],
      starPatentList: [],
      star_total: 0,
      starParams: {
        star_type: "",
        user: "",
        page: 1,
        page_size: 10,
      },
    };
  },
  methods: {
    handleCancelStar() {
      this.starParams.page = 1
      this.getUserStar()
    },
    // 点击加载更多
    handlePageChange(val) {
      this.starParams.page = val;
      this.getUserStar();
    },
    // 加载更多
    showMore() {
      this.starParams.page += 1;
      this.getUserStar();
    },
    // 响应父组件调用
    showFavorites(val) {
      this.starActive = "news";
      this.showStar = true;
      this.starParams.user = val;
      this.starParams.star_type = "news";
      this.starParams.page = 1;
      this.getUserStar();
    },
    // 响应点击收藏tab
    handleStarActive(val) {
      console.log(val.name);
      this.starParams.page = 1;
      this.starParams.star_type = val.name;
      this.getUserStar();
    },
    // 获取收藏内容
    getUserStar() {
      this.starLoading = true;
      api
        .getUserStar(this.starParams)
        .then((res) => {
          console.log(res);
          if (this.starParams.star_type == "news") {
            this.starNewsList = res.data;
          }
          if (this.starParams.star_type == "post") {
            this.starPostList = res.data;
          }
          if (this.starParams.star_type == "patent") {
            this.starPatentList = res.data;
          }
          this.star_total = res.count;
          this.starLoading = false;
        })
        .catch((error) => {
          this.starLoading = false;
          this.$message.error(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.favorites {
  #star {
    .el-dialog__body {
      padding-top: 0px;
    }
  }
  .starContent {
    width: 98%;
    // overflow-y: scroll;
    padding-right: 10px;
    margin-left: 10px;
    overflow: hidden;
    // height: 65vh;
    &-content {
      // overflow-y: scroll;
      height: 60vh;
    }
  }
  .starTab {
    width: 98%;
  }
}
</style>
